import settings from "../../settings";
import instance from "./instance.service";

import { getAuthHeader } from "./auth.service";

// const basePath = `${settings.API_URL}`;
const basePathV2 = `${settings.API_URL_V2}`;

export default {
  getComments(requestData, successCallback, errorCallback) {
    const request = {
      params: requestData,
      headers: getAuthHeader()
    };

    instance
      .get(`${basePathV2}comments/${requestData.training_id}/list`, request)
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  addComment(requestData, successCallback, errorCallback) {
    instance
      .post(`${basePathV2}comments/comment`, requestData, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
