<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <notifications />

    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              class="page-content"
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <div
                class="yf-carrotquest-notification-wrapper"
                id="yf-carrotquest-notification-wrapper"
              ></div>
              <transition class="page-content-wrapper" name="fade-in-up">
                <router-view @questionnaireOpen="questionnaireOpenFromPageProfile" />
              </transition>
            </div>

            <content-loader
              v-if="contentLoaderEnabled"
              v-bind:logo="contentLoaderLogo"
            ></content-loader>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>

    <!-- begin: Modal widget -->
    <modal
      class="questionnaire-modal"
      :maxWidth="600"
      width="90%"
      height="auto"
      :resizable="true"
      :adaptive="true"
      id="modal__user__is-not-full"
      name="modal__user__is-not-full"
      @opened="maskCreate"
      :clickToClose="false"
    >
      <form>
        <template v-if="!$store.getters.userData.is_fake_email">
          <span v-if="isPageProfile" class="modal-close" @click="hide"></span>
          <template v-else>
            <span v-if="step !== 5" class="modal-close" @click="closeModal"></span>
            <span v-if="step === 5" class="modal-close" @click="hide"></span>
          </template>
        </template>
        <div class="questionnaire-step" v-if="step === 1">
          <div class="questionnaire-nav">Шаг 1 из 4</div>
          <div class="questionnaire-title">
            Давай знакомиться! Расскажи о себе.
          </div>
          <div class="questionnaire-sub-title">
            Информация поможет составить персональную программу тренировок
          </div>
          <div class="questionnaire-wrap">
            <div v-if="$store.getters.userData.is_fake_email" class="questionnaire-item-wrap">
              <label for="userEmail" class="questionnaire-form-input-label">
                E-mail
              </label>
              <input
                class="form-control questionnaire-form-input"
                v-bind:class="{ 'error-border': errors.email }"
                type="text"
                placeholder="Укажите свой настоящий e-mail"
                id="userEmail"
                name="user.email"
                v-model.trim="user.email"
                v-on:keyup.delete="clearErrors"
                @keypress="clearErrors"
              />
              <p :class="{ active: errors.email }" class="questionnaire-form-text-error">{{ errors.email }}</p>
            </div>
            <div class="questionnaire-item-wrap">
              <label for="userName" class="questionnaire-form-input-label">
                Имя
              </label>
              <input
                class="form-control questionnaire-form-input"
                v-bind:class="{ 'error-border': errors.name }"
                type="text"
                placeholder="Введи имя"
                id="userName"
                name="user.name"
                v-model="user.name"
                v-on:keyup.delete="clearErrors"
                @keypress="clearErrors"
              />
            </div>
            <div class="questionnaire-item-wrap">
              <label for="userDob" class="questionnaire-form-input-label">
                Дата рождения
              </label>
              <input
                class="form-control questionnaire-form-input"
                v-bind:class="{ 'error-border': errors.dob }"
                placeholder="dd.mm.yyyy"
                data-inputmask-alias="datetime"
                data-inputmask-inputformat="dd.mm.yyyy"
                data-inputmask-placeholder="dd.mm.yyyy"
                id="userDob"
                name="user.dob"
                v-model="user.dob"
                v-on:keyup.delete="clearErrors"
                @keypress="clearErrors"
              />
            </div>
            <div class="questionnaire-item-wrap flex flex--center">
              <div class="questionnaire-item-wrap-block">
                <label for="userWeight" class="questionnaire-form-input-label">
                  Вес
                </label>
                <input
                  class="form-control questionnaire-form-input"
                  v-bind:class="{ 'error-border': errors.weight }"
                  type="number"
                  max="595"
                  min="30"
                  step="1"
                  pattern="[0-9]*"
                  placeholder="Вес (кг)"
                  id="userWeight"
                  name="user.weight"
                  v-model="user.weight"
                  v-on:keyup.delete="clearErrors"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="columne-divider--15"></div>
              <div class="questionnaire-item-wrap-block">
                <label for="userHeight" class="questionnaire-form-input-label">
                  Рост
                </label>
                <input
                  class="form-control questionnaire-form-input"
                  v-bind:class="{ 'error-border': errors.height }"
                  type="number"
                  max="236"
                  min="30"
                  step="1"
                  pattern="[0-9]*"
                  placeholder="Рост (см)"
                  id="userHeight"
                  name="user.height"
                  v-model="user.height"
                  v-on:keyup.delete="clearErrors"
                  @keypress="isNumber($event, true)"
                />
              </div>
            </div>
            <div class="questionnaire-item-wrap">
              <label for="instagram" class="questionnaire-form-input-label">
                Инстаграм
              </label>
              <input
                class="form-control questionnaire-form-input"
                type="text"
                placeholder="@"
                id="instagram"
                name="user.instagram"
                v-model="user.instagram"
                v-on:keyup.delete="clearErrors"
                @keypress="clearErrors"
              />
            </div>
          </div>
        </div>
        <div class="questionnaire-step" v-if="step === 2">
          <div class="questionnaire-nav">Шаг 2 из 4</div>
          <div class="questionnaire-title">Какой у тебя пол?</div>
          <div class="questionnaire-wrap">
            <div
              :key="'questionnaire-repeat-wrap-gender-' + index"
              v-for="(gender, index) in genders"
              class="questionnaire-repeat-wrap"
            >
              <input
                class="js-questionnaire-gender"
                type="radio"
                checked="gender.value === user.iu_gender"
                :id="'questionnaire-male-' + index"
                :key="'key-questionnaire-male-' + index"
                name="user.iu_gender"
                v-model="user.iu_gender"
                :value="gender.value"
                v-on:change="clearErrors"
              />
              <label
                :for="'questionnaire-male-' + index"
                class="questionnaire-check"
              >
                <span
                  :class="{
                    'gender-icon fa fa-venus': gender.value === 'female',
                    'gender-icon fa fa-mars': gender.value === 'male'
                  }"
                ></span>
                <span class="gender-name">{{ gender.name }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="questionnaire-step" v-if="step === 3">
          <div class="questionnaire-nav">Шаг 3 из 4</div>
          <div class="questionnaire-title">Какая у тебя цель?</div>
          <div class="questionnaire-wrap">
            <div
              :key="'questionnaire-repeat-wrap-target-' + index"
              v-for="(target, index) in targets"
              class="questionnaire-repeat-wrap"
            >
              <input
                class="js-questionnaire-target"
                type="radio"
                :id="'questionnaire-target-' + index"
                :key="'key-questionnaire-target-' + index"
                name="user.bmr_multiplier_id"
                v-model="user.bmr_multiplier_id"
                :value="target.value"
                v-on:change="clearErrors"
              />
              <label
                :for="'questionnaire-target-' + index"
                class="questionnaire-check"
              >
                {{ target.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="questionnaire-step" v-if="step === 4">
          <div class="questionnaire-nav">Шаг 4 из 4</div>
          <div class="questionnaire-title">
            Какой у тебя уровень подготовки?
          </div>
          <div class="questionnaire-wrap">
            <div
              :key="'questionnaire-repeat-wrap-level-' + index"
              v-for="(level, index) in levels"
              class="questionnaire-repeat-wrap"
            >
              <input
                class="js-questionnaire-level"
                type="radio"
                :id="'questionnaire-level-' + index"
                :key="'key-questionnaire-level-' + index"
                name="user.activity_level"
                v-model="user.activity_level"
                :value="level.value"
                v-on:change="clearErrors"
              />
              <label
                :for="'questionnaire-level-' + index"
                class="questionnaire-check"
              >
                {{ level.name }}
              </label>
            </div>
          </div>
        </div>
        <div class="questionnaire-step" v-if="step === 5">
          <div class="questionnaire-title" v-if="user.iu_gender">
            Спасибо!
          </div>
          <div class="questionnaire-sub-title" v-if="user.iu_gender">
            Мы обработали твои данные!
            <br />
            Персональная программа тренировок для тебя "{{
              recomendedProgram.name
            }}"
          </div>
          <div class="questionnaire-sub-title" v-if="!user.iu_gender">
            Информации для подбора программы не достаточно!
            <br />
            Попробуй тренировку из самой популярной программы "{{
              recomendedProgram.name
            }}"
          </div>
        </div>
        <div
          class="questionnaire-button-wrap"
          v-bind:class="{ right: step === 1, left: step === 4 }"
        >
          <button
            class="questionnaire-button-prev"
            v-if="step > 1 && step < 5"
            v-on:click="prevStep()"
            type="button"
          >
            Назад
          </button>
          <button
            class="questionnaire-button-next"
            v-if="step < 4"
            v-on:click="nextStep()"
            type="button"
          >
            Далее
          </button>
          <button
            class="questionnaire-button-save"
            v-else-if="step === 4"
            v-on:click="nextStep()"
            type="button"
          >
            Отправить
          </button>
          <button
            class="questionnaire-button-program"
            v-else-if="step === 5"
            v-on:click="showProgram(recomendedProgram.id)"
            type="button"
          >
            Открыть
          </button>
        </div>
      </form>
    </modal>
    <!-- end: Modal widget -->
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import "bootstrap";
import { mapGetters } from "vuex";
import KTAside from "@/components/layout/aside/index.vue";
import KTHeader from "@/components/layout/header/index.vue";
import KTHeaderMobile from "@/components/layout/header/Mobile.vue";
import KTFooter from "@/components/layout/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/components/layout/header/Sub.vue";
import KTStickyToolbar from "@/components/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/components/layout/extras/ScrollTop";
import Loader from "@/components/content/Loader/index.vue";
import ContentLoader from "@/components/content/Loader/Content.vue";

import { SET_TOKEN } from "@/core/services/store/auth.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import InputMask from "inputmask";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    ContentLoader
  },
  data() {
    return {
      targets: [
        { name: "Быть в тонусе", value: 1 },
        { name: "Похудеть", value: 2 },
        { name: "Стать сильнее", value: 3 },
        { name: "Улучшить гибкость", value: 4 }
      ],
      levels: [
        { name: "Начальный", value: 1 },
        { name: "Средний", value: 2 },
        { name: "Продвинутый", value: 3 }
      ],
      genders: [
        { name: "Мужчина", value: "male" },
        { name: "Женщина", value: "female" }
      ],
      modalHeight: "",
      modalWidth: 455,
      messages: [],
      message: "",
      errors: {},
      step: 1,
      recomendedProgram: {
        id: null,
        name: ""
      },
      user: {
        email: "",
        name: "",
        instagram: "@",
        iu_gender: "",
        activity_level: null,
        bmr_multiplier_id: null,
        dob: "",
        weight: "",
        height: ""
      }
    };
  },
  beforeMount() {
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    const startRoute = this.$route;

    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    if (this.$route.query.token) {
      setTimeout(() => {
        this.$store
          .dispatch(SET_TOKEN, {
            token: this.$route.query.token
          })
          .then(() => {
            return this.$store.dispatch("getUser");
          })
          .then(() => {
            const user = this.$store.getters.userData;
            this.questionnaireOpen(user);
            this.redirectOnStart(user);
            this.authCarrotQuest(user);
          })
          .catch(() => {
            window.location.href = "https://fitstars.ru/login";
          });
      }, 2000);
    } else if (!this.isAuthenticated) {
      window.location.href = "https://fitstars.ru/login";
    } else if (
      this.isAuthenticated &&
      (startRoute.name === "layout" || startRoute.name === "migrate")
    ) {
      this.$store.dispatch("getUser").then(() => {
        const user = this.$store.getters.userData;
        this.questionnaireOpen(user);
        this.redirectOnStart(user);
        this.authCarrotQuest(user);
      });
    } else {
      this.$store.dispatch("getUser").then(() => {
        const user = this.$store.getters.userData;
        this.questionnaireOpen(user);
        this.authCarrotQuest(user);
      });
    }

    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {
    authCarrotQuest(user) {
      window.carrotquest.auth(user.id, user.carrotquest_hash);
      window.carrotquest.track("$authorized");
      window.carrotquest.identify([
        {
          op: "set_once",
          key: "cq_invite_link",
          value: user.cq_invite_link
        },
        {
          op: "set_once",
          key: "has_programs",
          value: user.cq_hasprograms
        },
        {
          op: "update_or_create",
          key: "$email",
          value: user.email
        },
        {
          op: "update_or_create",
          key: "$phone",
          value: user.iu_telephone ? user.iu_telephone : ""
        },
        {
          op: "update_or_create",
          key: "$name",
          value: user.name ? user.name : ""
        },
        {
          op: "update_or_create",
          key: "gender",
          value: user.iu_gender
        },
        {
          op: "update_or_create",
          key: "lose_weigth",
          value: user.rec_lose_weight
        },
        {
          op: "update_or_create",
          key: "rec_health",
          value: user.rec_health
        },
        {
          op: "update_or_create",
          key: "rec_pump_up_muscles",
          value: user.rec_pump_up_muscles
        },
        {
          op: "update_or_create",
          key: "rec_stretch",
          value: user.rec_stretch
        },
        {
          op: "update_or_create",
          key: "surname",
          value: user.surname ? user.surname : ""
        },
        {
          op: "update_or_create",
          key: "url_slug",
          value: user.url_slug ? user.url_slug : ""
        },
        {
          op: "update_or_create",
          key: "training_level",
          value: user.rec_training_level
        },
        {
          op: "update_or_create",
          key: "subscription_ending_at",
          value: user.cq_subscription_end_date
        },
        {
          op: "delete",
          key: "subscription_end_date",
          value: "0"
        },
        {
          op: "update_or_create",
          key: "subscription_active",
          value: user.cq_subscription_active
        },
        {
          op: "update_or_create",
          key: "subscription_days_left",
          value: user.cq_subscription_days_left
        },
        {
          op: "update_or_create",
          key: "recurring_payments",
          value: user.cq_recurring_payments
        },
        {
          op: "update_or_create",
          key: "total_subscription_days",
          value: user.cq_total_subscription_days
        }
      ]);
    },
    showProgram(programId) {
      this.closeModal();
      this.$router.push({
        name: "training-pack-pack",
        params: { pack: programId }
      });

      if (this.isPageProfile) {
        this.hide();
      }
    },
    isNumber(evt, isInteger = false) {
      let evt1;
      let charCode;

      this.clearErrors();

      if (evt) {
        evt1 = evt;
      } else {
        evt1 = window.event;
      }
      if (evt1.which) {
        charCode = evt1.which;
      } else {
        charCode = evt1.keyCode;
      }

      if (
        (isInteger === false &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46) ||
        (isInteger === true &&
          ((charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46) ||
            charCode === 190 ||
            charCode === 188 ||
            charCode === 46))
      ) {
        evt1.preventDefault();
      }

      return true;
    },
    maskCreate() {
      InputMask().mask(document.querySelectorAll("input"));
    },
    async nextStep() {
      let userData = {};

      if (this.step === 1 && this.validateUserForm()) {
        return;
      }

      switch (this.step) {
        case "1":
        case 1:
          if (this.$store.getters.userData.is_fake_email && this.user.email) {
            try {
              await this.$store.dispatch("updateUser", {
                email: this.user.email,
                username: this.user.email,
                is_fake_email: 0
              });
            } catch (e) {
              if (e.response?.data?.data.toUpperCase().includes('EMAIL')) {
                this.$set(this.errors, "email", e.response.data.data);
              }

              return;
            }
          }
          if (this.user.name) {
            userData.name = this.user.name;
          }
          if (this.user.dob) {
            userData.dob = this.user.dob;
          }
          if (this.user.weight) {
            userData.weight = this.user.weight;
          }
          if (this.user.height) {
            userData.height = this.user.height;
          }
          if (this.user.instagram) {
            userData.instagram = this.user.instagram;
          }

          break;
        case "2":
        case 2:
          if (this.user.iu_gender) {
            userData.iu_gender = this.user.iu_gender;
          }
          break;
        case "3":
        case 3:
          if (this.user.bmr_multiplier_id) {
            userData.bmr_multiplier_id = this.user.bmr_multiplier_id;
          }
          break;
        case "4":
        case 4:
          if (this.user.activity_level) {
            userData.activity_level = this.user.activity_level;
          }
          break;
        default:
          this.hide();
          return;
      }

      if (this.isPageProfile && this.step !== 4) {
        this.step = this.step + 1;
      } else {
        if (this.isPageProfile) {
          userData = this.user;
        }

        this.updateUserData(userData);
      }
    },
    prevStep() {
      if (this.step === 1) {
        return;
      }
      this.step = this.step - 1;
    },
    show() {
      this.$modal.show("modal__user__is-not-full");
    },
    hide() {
      this.$modal.hide("modal__user__is-not-full");
    },
    clearUserData() {
      this.step = 1;

      this.user = {
        email: "",
        name: "",
        instagram: "@",
        iu_gender: "",
        activity_level: null,
        bmr_multiplier_id: null,
        dob: "",
        weight: "",
        height: ""
      };

      this.clearErrors();
    },
    clearErrors() {
      this.errors = {};
    },
    validateUserForm() {
      let isError;
      isError = false;

      if (this.user.dob) {
        const date = this.user.dob.split(".");
        const day = parseInt(date[0], 10);
        const month = parseInt(date[1], 10);
        const year = parseInt(date[2], 10);
        const dateObj = new Date(year, month, day, 0, 0, 0, 0);

        const dateNow = new Date();
        if (
          Number.isNaN(dateObj.getTime()) ||
          year >= dateNow.getFullYear() ||
          year <= 1920
        ) {
          this.$set(this.errors, "dob", "Не верный формат даты");
          isError = true;
        }
      }

      if (!this.user.name || this.user.name.toString().length === 0) {
        this.$set(this.errors, "name", "Необходимо заполнить поле");
        isError = true;
      }

      if (this.$store.getters.userData.is_fake_email) {
        if (!this.user.email) {
          this.$set(this.errors, "email", "Необходимо заполнить поле");
          isError = true;
        } else if (this.user.email === this.$store.getters.userData.email) {
          this.$set(this.errors, "email", "Почта не была изменена");
          isError = true;
        } else if (this.user.email.length < 6) {
          this.$set(this.errors, "email", "Поле email должно быть от 6 символов");
          isError = true;
        } else if (!this.validateEmail(this.user.email)) {
          this.$set(this.errors, "email", "Введите корректный e-mail");
          isError = true;
        }
      }

      if (this.user.weight && this.user.weight < 25) {
        this.$set(this.errors, "weight", "Вес не может быть ниже 25 кг");
        isError = true;
      } else if (this.user.weight && this.user.weight > 300) {
        this.$set(this.errors, "weight", "Вес не может превышать 300 кг");
        isError = true;
      }

      if (this.user.height && this.user.height < 0) {
        this.$set(this.errors, "height", "Не может быть отрицательным числом");
        isError = true;
      } else if (this.user.height && this.user.height > 236) {
        this.$set(this.errors, "height", "Слишком большое значение");
        isError = true;
      }

      return isError;
    },
    closeModal() {
      this.$store
        .dispatch("updateUser", {
          is_show_modal: 0
        })
        .then(() => {
          this.$store.dispatch("getUser");
          this.hide();
        })
        .catch(error => {
          this.messages.push(error);
        });
    },
    updateUserData(userData) {
      this.$store
        .dispatch("updateFoodUserDataModal", userData)
        .then(response => {
          if (response && response.program) {
            this.recomendedProgram.id = response.program.id;
            this.recomendedProgram.name = response.program.name;
          }

          this.$store.dispatch("getUser");
          if (this.isPageProfile) {
            this.$store.dispatch("getFoodUserData");
          }
          this.step = this.step + 1;
        })
        .catch(error => {
          this.messages.push(error);
        });
    },
    questionnaireOpen(userData) {
      if (userData.name) {
        this.user.name = userData.name;
      }

      if (userData.email) {
        this.user.email = userData.email;
      }

      if (userData.is_show_modal === "1") {
        this.show();
      }
    },
    questionnaireOpenFromPageProfile(userData) {
      this.clearUserData();

      if (userData.name) {
        this.user.name = userData.name;
      }

      if (userData.email) {
        this.user.email = userData.email;
      }

      this.show();
    },
    redirectOnStart(userData) {
      if (parseInt(userData.cq_subscription_days_left, 10) < 1) {
        this.$router.push({ name: "programs" });
      } else {
        if (this.$route.name == 'training-pack-pack') {
          this.$router.push(this.$route.path);
        } else {
          this.$router.push({ name: "programs-my" });
        }
      }

      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;

      return re.test(email);
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if the content loader is enabled
     * @returns {boolean}
     */
    contentLoaderEnabled() {
      return !/false/.test(this.layoutConfig("content-loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Content loader logo image using require() function
     * @returns {string}
     */
    contentLoaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("content-loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      return !!this.layoutConfig("toolbar.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    isPageProfile() {
      return this.$route.name === 'new-profile';
    }
  }
};
</script>

<style>
#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  font-family: Raleway;
  padding: 40px;
  width: 70%;
  min-width: 300px;
}

/* Mark input boxes that gets an error on validation: */
input.invalid {
  background-color: #ffdddd;
}

/* Hide all steps by default: */
.tab {
  display: none;
}
.questionnaire-modal {
  z-index: 1000 !important;
}

/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.questionnaire-step {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .questionnaire-step {
    max-height: 450px;
    overflow-y: scroll;
  }
}

.questionnaire-check {
  display: flex;
  background: #edf1f5;
  border-radius: 5px !important;
  max-width: 265px;
  margin: 16px auto 16px;
  text-align: center;
  padding: 14px 25px;
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

input[type="radio"]:checked + .questionnaire-check {
  background: #ef16b0;
  color: #fff;
}

.questionnaire-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 123%;
  text-align: center;
  color: #4b4b4b;
}

.label-block {
  display: inline-block;
  text-align: left;
}

input[type="radio"] {
  display: none;
  visibility: visible;
  width: auto;
}

.questionnaire-item-wrap input {
  border-radius: 8px !important;
  outline: none;
  height: 40px;
  border: 1px solid #d8e5eb;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 16px;
  max-width: 265px;
}

.questionnaire-form-input.error-border {
  border: 1px solid #ff0000;
}

.questionnaire-form-input-label {
  font-size: 16px;
  line-height: 22px;
  color: #4b4b4b;
  font-weight: bold;
}

.questionnaire-item-wrap {
  max-width: 265px;
  margin: 0 auto;
  text-align: left;
  margin-top: 17px;
}

.questionnaire-form-text-error {
  position: absolute;
  font-size: 12px;
  color: #ff0000;
  opacity: 0;
  transition: .3s;
}

.questionnaire-form-text-error.active {
  opacity: 1;
}

.questionnaire-item-wrap-block {
  display: block;
  width: 50%;
}

.questionnaire-button {
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  padding: 9px 25px 9px;
  border: 1px solid #d8e5eb;
  box-sizing: border-box;
  border-radius: 5px;
  background: #edf1f5;
}

.questionnaire-nav {
  font-size: 16px;
  line-height: 22px;
  color: #4b4b4b;
  margin-bottom: 16px;
}

.questionnaire-sub-title {
  margin-top: 12px;
  max-width: 300px;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  color: #4b4b4b;
}

.questionnaire-button-next,
.questionnaire-button-prev,
.questionnaire-button-save,
.questionnaire-button-program {
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  transition: 0.3s;
}

.questionnaire-button-next {
  background: #ef16b0;
  border: 1px solid #ef16b0;
  color: #fff !important;
  padding: 9px 50px 9px 25px;
}
.questionnaire-button-prev {
  background: transparent;
  border: 1px solid #d8e5eb;
  color: #4b4b4b;
  padding: 9px 25px 9px 50px;
}
.questionnaire-button-save {
  background: #ef16b0;
  border: 1px solid #ef16b0;
  color: #fff !important;
  min-width: 123px;
}
.questionnaire-button-program {
  background: #ef16b0;
  border: 1px solid #ef16b0;
  color: #fff;
  min-width: 123px;
  padding: 9px 0;
  margin: auto;
}

.questionnaire-button-next:after {
  background: url("~@/assets/img/arrow-right-white.svg") no-repeat;
  content: "";
  width: 20px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.questionnaire-button-prev:after {
  background: url("~@/assets/img/arrow-left.svg") no-repeat;
  content: "";
  width: 20px;
  height: 10px;
  position: absolute;
  left: 20px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.questionnaire-button-wrap {
  max-width: 265px;
  text-align: center;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
}

.questionnaire-button-wrap.right {
  justify-content: right;
}

#prevBtn {
  background: transparent;
}

.step.active {
  opacity: 1;
}

.gender-icon {
  font-size: 24px;
  padding-right: 10px;
}

.text-decoration-underline {
  text-decoration: underline;
}

.card-footer--settings {
  border-top: none;
}

@media screen and (max-width: 767px) {
  .questionnaire-item-wrap {
    margin-top: 10px;
  }
  .questionnaire-button-wrap {
    margin: 20px auto 0;
  }
}
</style>
