import $ from "jquery";
import moment from "moment";
import trainingService from "../../services/training.service";
import programsService from "../../services/programs.service";

export default {
  state: {
    completed: null,
    training: {},
    trainingPlan: [],
    calendar: {},
    comments: [],
    // comments: {
    //   current_page: null,
    //   data: [],
    //   from: null,
    //   last_page: null,
    //   next_page_url: null,
    //   per_page: null,
    //   prev_page_url: null,
    //   to: null,
    //   total: null
    // },
    isLoading: {
      trainings: false,
      trainingPlan: false,
      favouriteTrainings: false,
      comments: false,
      addComment: false
    }
  },
  getters: {
    trainingTraining: state => state.training.training,
    trainingProgram: state => state.training.program,
    trainingPrograms: state => state.training.programsSelector,
    trainingTrainer: state => state.training.trainer,
    trainingDoneButton: state => state.training.doneButton,
    trainingPlan: state => state.training.trainings,

    trainingsCalendar: state => state.calendar,
    trainingComments: state => state.comments,
    trainingCompletedCount: state => state.completed,
    isTrainingLoading: state => state.isLoading
  },
  actions: {
    logTrainingYoutubeData(context, data) {
      return new Promise((resolve, reject) => {
        trainingService.logTrainingYoutubeData(
          data,
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getTraining({ commit }, data) {
      if (data.initLoading) {
        commit("training_loading_start");
      }

      return new Promise((resolve, reject) => {
        trainingService.getTraining(
          data,
          response => {
            commit("set_training", response);
            resolve(response);

            if (data.initLoading) {
              commit("training_loading_finish");
            }
          },
          error => {
            reject(error);

            if (data.initLoading) {
              commit("training_loading_finish");
            }
          }
        );
      });
    },
    clearTraining({ commit }) {
      commit("clear_training");
    },
    getTrainingsCalendar({ commit }) {
      return new Promise((resolve, reject) => {
        trainingService.getTraining(
          {},
          response => {
            commit("set_trainings_calendar", response.calendar);

            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getTrainingPlan({ commit }, planData) {
      commit("training_plan_loading_start");
      return new Promise((resolve, reject) => {
        trainingService.getTrainingPlan(
          planData,
          data => {
            commit("set_training_plan", data);
            commit("training_plan_loading_finish");
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getPrevMonth({ commit }, calendarData) {
      return new Promise((resolve, reject) => {
        trainingService.getPrevMonth(
          calendarData,
          data => {
            commit("set_trainings_calendar", data);
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getNextMonth({ commit }, calendarData) {
      return new Promise((resolve, reject) => {
        trainingService.getNextMonth(
          calendarData,
          data => {
            commit("set_trainings_calendar", data);
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getTrainingPack(context, packData) {
      return new Promise((resolve, reject) => {
        trainingService.getTrainingPack(
          packData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    getFavoriteTrainings({ commit }) {
      commit("favourite_training_loading_start");

      return new Promise((resolve, reject) => {
        trainingService.getFavoriteTrainings(
          data => {
            resolve(data);
            commit("favourite_training_loading_finish");
          },
          error => {
            reject(error);
            commit("favourite_training_loading_finish");
          }
        );
      });
    },
    getTrainingComments({ commit }, comments) {
      if (comments.preloader) {
        commit("training_comments_loading_start");
      }

      return new Promise((resolve, reject) => {
        trainingService.getComments(
          comments.page,
          comments.trainingId,
          data => {
            commit("set_trainings_comments", data);
            commit("training_comments_loading_finish");
            resolve(data);
          },
          error => {
            commit("training_comments_loading_finish");
            reject(error);
          }
        );
      });
    },
    getTrainingsCompleted({ commit }) {
      return new Promise((resolve, reject) => {
        trainingService.getTrainingsCompleted(
          completed => {
            resolve(completed);
            commit("set_trainings_completed", completed);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    addTrainingComment({ commit }, commentData) {
      commit("add_training_comments_start");

      return new Promise((resolve, reject) => {
        trainingService.addComment(
          commentData,
          data => {
            resolve(data);
            commit("add_training_comments_finish");
          },
          error => {
            reject(error);
            commit("add_training_comments_finish");
          }
        );
      });
    },
    likeTraining(context, trainingData) {
      return new Promise((resolve, reject) => {
        trainingService.likeTraining(
          trainingData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    dislikeTraining(context, trainingData) {
      return new Promise((resolve, reject) => {
        trainingService.dislikeTraining(
          trainingData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    switchProgramMyProgram({ commit }, packData) {
      return new Promise((resolve, reject) => {
        programsService.switchProgramMyProgram(
          packData,
          data => {
            resolve(data);
            commit("switch_program_selected");
          },
          error => {
            reject(error);
          }
        );
      });
    },
    setFavoriteTraining(context, trainingData) {
      if (trainingData.state) {
        return new Promise((resolve, reject) => {
          trainingService.setFavoriteTraining(
            trainingData,
            data => {
              resolve(data);
            },
            error => {
              reject(error);
            }
          );
        });
      }

      return new Promise((resolve, reject) => {
        trainingService.removeFavoriteTraining(
          trainingData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    setTrainingReady({ commit }, trainingData) {
      return new Promise((resolve, reject) => {
        trainingService.setTrainingReady(
          trainingData,
          data => {
            resolve(data);
            commit("set_training_ready");
          },
          error => {
            reject(error);
          }
        );
      });
    },
    deleteTrainingReady(context, trainingData) {
      return new Promise((resolve, reject) => {
        trainingService.deleteTrainingReady(
          trainingData,
          data => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    clearTrainingPlan({ commit }) {
      commit("clear_training_plan");
    },
    switchTraining({ commit }, training) {
      commit("switch_training", training);
    }
  },
  mutations: {
    clear_training(state) {
      state.completed = null;
      state.training = {};
      state.trainingPlan = [];
      state.comments = {
        current_page: null,
        data: [],
        from: null,
        last_page: null,
        next_page_url: null,
        per_page: null,
        prev_page_url: null,
        to: null,
        total: null
      };
      state.isLoading = {
        trainings: false,
        trainingPlan: false,
        favouriteTrainings: false,
        comments: false,
        addComment: false
      };
    },
    switch_program_selected(state) {
      const isSelected = state.training.program.is_selected;
      let trainings = state.training.trainings;
      let datePlaned = new Date();
      let isFirst = false;
      let i = 0;
      // let trainingsWithRelax = [];

      switch (isSelected) {
        case true:
          break;
        case false:
        default:
          $.each(trainings, (index, training) => {
            training.is_next_planed = !isFirst;
            training.date_planned = moment(datePlaned).format("YYYY-MM-DD");
            training.date_last_done = null;

            datePlaned = moment(training.date_planned)
              .add("days", parseInt(+training.rest_days + 1))
              .format("YYYY-MM-DD");

            if (isFirst === false) {
              isFirst = true;
            }

            i = i + 1;
          });

          break;
      }

      state.training.program.is_selected = !state.training.program.is_selected;
      state.training.program.progress = 0;
      state.training.trainings = trainings;
    },
    set_training_ready(state) {
      let program = state.training.program;
      let trainings = state.training.trainings;
      let isSetNext = false;
      let findedTrainingIndex = null;
      let findedDateLastDone = null;
      let findedTraining = null;
      let tempDatePlanned = null;
      let trainingsWithRelax = [];

      $.each(trainings, (index, training) => {
        const dateNow = moment(new Date());

        if (state.training.training.id === training.id) {
          findedTrainingIndex = index;
          findedTraining = training;
          findedDateLastDone = training.date_last_done
            ? training.date_last_done
            : null;

          tempDatePlanned = dateNow
            .add("days", parseInt(+training.rest_days + 1))
            .format("YYYY-MM-DD");

          findedTraining.date_planned = false;
          findedTraining.date_last_done = moment(new Date()).format(
            "YYYY-MM-DD"
          );
          findedTraining.is_next_planed = false;
        }
      });

      $.each(trainings, (index, training) => {
        if (training.date_last_done === null) {
          const tmp = tempDatePlanned;

          tempDatePlanned = moment(tempDatePlanned)
            .add("days", parseInt(+training.rest_days + 1))
            .format("YYYY-MM-DD");

          trainings[index].date_planned = tmp;
          trainings[index].is_next_planed = !isSetNext;

          isSetNext = true;
        }
      });

      trainings[findedTrainingIndex] = findedTraining;
      $.each(trainings, (index, training) => {
        if (!training.isRestDay) {
          trainingsWithRelax.push(training);

          if (training.rest_days && training.rest_days > 0) {
            for (let i = 1; i <= training.rest_days; i++) {
              let restDay = {
                date_planned: "",
                isRestDay: true
              };

              restDay.date_planned = moment(
                !training.date_planned
                  ? training.date_last_done
                  : training.date_planned
              )
                .add("days", i)
                .format("YYYY-MM-DD");

              trainingsWithRelax.push(restDay);
            }
          }
        }
      });

      if (findedDateLastDone == null) {
        const progress = program.progress.split("/");

        program.progress = `${parseInt(progress[0]) + 1}/${progress[1]}`;
        state.training.program = program;
      }
      state.training.trainings = trainingsWithRelax;
      state.training.training = findedTraining;
    },
    switch_training(state, trainingToSwitch) {
      let isNeedFirst =
        trainingToSwitch.id && trainingToSwitch.id > 0 ? false : true;
      let switchedToTraining = false;

      $.each(state.training.trainings, (index, training) => {
        if (switchedToTraining === false && isNeedFirst === true) {
          switchedToTraining = true;
          training.is_next_planed = true;
          training.date_last_done = null;
          state.training.training = training;
        } else if (
          switchedToTraining === false &&
          isNeedFirst === false &&
          training.id === trainingToSwitch.id
        ) {
          switchedToTraining = true;
          state.training.training = training;
        }
      });
    },
    clear_training_plan(state) {
      state.trainingPlan = [];
    },
    set_training(state, training) {
      let trainings = training.trainings;
      let trainingsWithRelax = [];

      $.each(trainings, (index, training) => {
        trainingsWithRelax.push(training);

        if (training.rest_days && training.rest_days > 0) {
          for (let i = 1; i <= training.rest_days; i++) {
            let restDay = {
              date_planned: "",
              isRestDay: true
            };

            restDay.date_planned = moment(
              training.date_planned != null
                ? training.date_planned
                : training.date_last_done
            )
              .add("days", i)
              .format("YYYY-MM-DD");

            trainingsWithRelax.push(restDay);
          }
        }
      });

      training.trainings = trainingsWithRelax;
      state.training = training;
    },
    set_trainings_calendar(state, calendar) {
      state.calendar = calendar;
    },
    set_training_plan(state, trainingPlan) {
      state.trainingPlan = trainingPlan;
    },
    set_trainings_comments(state, comments) {
      // const { data } = comments;

      // state.comments.current_page = data.current_page;
      // state.comments.from = data.from;
      // state.comments.last_page = data.last_page;
      // state.comments.next_page_url = data.next_page_url;
      // state.comments.per_page = data.per_page;
      // state.comments.prev_page_url = data.prev_page_url;
      // state.comments.to = data.to;
      // state.comments.total = data.total;

      // if (comments.page) {
      //   state.comments.data.push(...data.data);
      // } else {
      //   state.comments.data = data.data;
      // }
      state.comments = comments;
    },
    training_plan_loading_start(state) {
      state.isLoading.trainingPlan = true;
    },
    training_plan_loading_finish(state) {
      state.isLoading.trainingPlan = false;
    },
    training_loading_start(state) {
      state.isLoading.trainings = true;
    },
    training_loading_finish(state) {
      state.isLoading.trainings = false;
    },
    favourite_training_loading_start(state) {
      state.isLoading.favouriteTrainings = true;
    },
    favourite_training_loading_finish(state) {
      state.isLoading.favouriteTrainings = false;
    },
    training_comments_loading_start(state) {
      state.isLoading.comments = true;
    },
    training_comments_loading_finish(state) {
      state.isLoading.comments = false;
    },
    add_training_comments_start(state) {
      state.isLoading.addComment = true;
    },
    add_training_comments_finish(state) {
      state.isLoading.addComment = false;
    },
    set_trainings_completed(state, completed) {
      state.completed = completed;
    }
  }
};
