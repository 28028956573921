<template>
  <div
    v-if="recipe && indexCategory >= 0 && indexRecipe >= 0"
    class="catalog--item__link"
  >
    <div class="catalog-item-wrapper">
      <div class="catalog--item__image">
        <img class="img" :src="`${recipe.thumb}`" />
      </div>
      <div class="catalog--item__description">
        <p class="nutrition-list-item-info-description">
          {{ recipe.type.name }}
        </p>
        <p class="catalog--item__description--name">
          {{ recipe.name }}
        </p>
        <div class="d-flex catalog--item__description--data">
          <div class="kkal">{{ recipe.kkal }} Ккал</div>
          <div class="cook-time">
            <img
              class="disctop-icon"
              src="@/assets/img/recipe/new/cock-time.svg"
              alt=""
            />
            <img class="mobile-icon" src="@/assets/img/icon-clock.png" alt="" />
            {{ recipe.cook_time }}
          </div>
          <div class="rating">
            <img
              class="disctop-icon"
              src="@/assets/img/recipe/new/star.svg"
              alt=""
            />
            <img class="mobile-icon" src="@/assets/img/icon-star.png" alt="" />
            {{ recipe.rating.toFixed(1) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FSRecipeItem",
  props: {
    recipe: Object,
    userSubscriptions: Object,
    indexRecipe: Number,
    indexCategory: Number
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
