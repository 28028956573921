<template>
  <div
    class="training-video"
    :class="{
      'no-subscription': !userSubscriptions && program.tariff == null
    }"
  >
    <div class="training-video__header flex flex--center flex--wrap">
      <div class="training-video__header--title">{{ training.name }}</div>
      <div class="d-flex flex--center flex--wrap">
        <div class="users-on">{{ training.count_users }}</div>
        <div class="columne-divider columne-divider--15"></div>
        <span
          :id="`training-list__item--title-${training.id}`"
          :class="
            `complexity-level complexity-level--${Math.ceil(
              training.training_level / 3
            )}`
          "
        ></span>
        <b-tooltip
          :target="`training-list__item--title-${training.id}`"
          variant="light"
          placement="bottom"
        >
          {{
            `Сложность: ${
              Math.ceil(training.training_level / 3) === 1
                ? "легкая"
                : Math.ceil(training.training_level / 3) === 2
                ? "средняя"
                : "высокая"
            }`
          }}
        </b-tooltip>
        <div class="columne-divider columne-divider--15"></div>
        <div class="calories-done">
          {{ `${parseInt(training.calories, 10)} Ккал` }}
        </div>
      </div>
    </div>
    <div class="training-video__player">
      <video-player
        :trainer="trainer"
        :training="training"
        :program="program"
        :trainings="trainings"
      ></video-player>
    </div>
    <div class="columne-divider columne-divider--12 hide-on--tablett"></div>
    <div class="training-video__footer flex flex--center flex--wrap">
      <button
        v-if="!training.date_last_done"
        class="btn btn--succses"
        @click="readyTraining(true)"
      >
        Готово!
      </button>
      <div style="display: flex" v-if="training.date_last_done">
        <span class="btn-done">
          <img
            style="margin-right: 5px"
            src="@/assets/img/training/check.svg"
          />
          {{
            `Выполнена: ${getFormattedDate(training.date_last_done, "DD.MM")}`
          }}
        </span>
        <div class="columne-divider columne-divider--12"></div>
        <button class="btn btn--default fake-btn" @click="confirmDeleteTrainingReady">
          <img src="@/assets/img/training/basket.svg" />
        </button>
        <div class="columne-divider columne-divider--12"></div>
        <button class="btn btn--default fake-btn" @click="readyTraining(true)">
          <img src="@/assets/img/training/revers.svg" />
        </button>
      </div>
      <div
        class="training-video__footer--switch-block"
        style="font-size: 13px;color: #7E8298;"
      >
        🐌 Проблемы с видео? Переключиться на
        <div class="switch-player" @click="switchPlayer">
          другой плеер
        </div>
      </div>
      <div class="training-btn-wr">
        <div
          @click="likeTraining"
          class="fake-btn like"
          :class="{ liked: training.is_liked == 1 }"
        >
          <i class="flaticon-black"></i> {{ training.likes }}
        </div>
        <div class="columne-divider columne-divider--15"></div>
        <div
          class="fake-btn"
          :class="{ 'is-favorite': training.isInFavorites == true }"
          @click="favoriteTraining"
        >
          <i class="fas fa-star"></i>
        </div>
      </div>
    </div>
    <div
      v-if="
        (training.steps && Object.keys(training.steps).length > 0) ||
          training.steps_description ||
          training.description
      "
    >
      <div class="dividing-line"></div>
      <div class="training-video__info--title">Дополнительные материалы</div>
    </div>
    <div v-if="training.description" class="training-video__info">
      <div
        class="training-video__info--txt"
        v-html="training.description"
      ></div>
    </div>
    <!-- if steps stars -->
    <div
      v-if="training.steps && Object.keys(training.steps).length > 0"
      class="dividing-line"
    ></div>
    <div
      v-if="training.steps && Object.keys(training.steps).length > 0"
      class="training-video__steps"
    >
      <div
        class="training-list__item cursor-pointer"
        v-for="(step, index) in training.steps"
        :key="`training-steps-${index}`"
        @click="stepModalOpen(step.text, step.v)"
      >
        <div v-if="step.v" class="training-list__item--img">
          <img :src="`https://img.youtube.com/vi/${step.v}/mqdefault.jpg`" />
          <!-- <div class="training-list__item--duration">18:57</div> -->
        </div>
        <div v-if="step.v" class="training-list__item--info-wr">
          <div class="training-list__item--title-wr">
            <div style="max-width: 300px" class="training-list__item--title">
              {{ step.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- if steps end -->
    <div v-if="training.steps_description" class="dividing-line"></div>
    <div
      v-if="training.steps_description"
      class="training-video__info training-video__info-steps-description"
    >
      <div class="training-video__info--title">Дополнительные материалы</div>
      <div
        class="training-video__info--txt"
        v-html="training.steps_description"
      ></div>
    </div>

    <div aria-hidden="true" class="modal fade" id="video" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              @click="stepModalClose()"
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
            ></button>
            <h4 class="modal-title">
              <strong>{{ stepModal.title }}</strong>
            </h4>
          </div>
          <div class="modal-body modal-body--nopadding">
            <iframe
              style="border: none"
              v-if="stepModal.id"
              :src="
                `https://www.youtube.com/embed/${stepModal.id}?rel=0&showinfo=0&enablejsapi=1`
              "
              allow="autoplay; encrypted-media"
              allowfullscreen
              height="340"
              width="100%"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";

import VideoPlayer from "@/components/VideoPlayer.vue";

import { getFormattedDate } from "@/assets/js/helpers";

export default {
  name: "FSTrainingTraining",
  props: {
    training: Object,
    trainer: Object,
    program: Object,
    trainings: Array,
  },
  components: {
    VideoPlayer
  },
  data() {
    return {
      stepModal: {
        title: "",
        id: ""
      }
    };
  },
  computed: {
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    }
  },
  beforeDestroy() {
    this.$root.$off("videoplayer.is.ready");
  },
  mounted() {
    let __self__ = this;

    this.$root.$on("videoplayer.is.ready", function() {
      __self__.readyTraining();
    });

    $("#video").on("hidden.bs.modal", () => {
      __self__.stepModal.id = null;
      __self__.stepModal.title = null;
    });
  },
  methods: {
    getFormattedDate,
    switchPlayer() {
      this.$store
        .dispatch("updateUser", {
          switch_player: true
        })
        .then(() => {
          return window.location.reload();
          //return this.$store.dispatch("getUser");
        })
        /*.then(() => {
          this.$root.$emit("reloadVideoPlayer", true);
        });*/
    },
    showMessage() {
      this.$notify({
        type: "success",
        title:
          "Ты просмотрел более 50% тренировки, твой прогресс автоматически сохранен"
      });
    },
    stepModalOpen(title, id) {
      this.stepModal.title = title;
      this.stepModal.id = id;

      $("#video").modal("toggle");
    },
    stepModalClose() {
      this.stepModal.title = "";
      this.stepModal.id = "";
    },
    favoriteTraining() {
      this.training.isInFavorites =
        this.training.isInFavorites == true ? false : true;

      this.$store
        .dispatch("setFavoriteTraining", {
          id: this.training.id,
          state: this.training.isInFavorites
        })
        .then(() => {
          this.$notify({
            type: "success",
            title:
              this.training.isInFavorites == true
                ? "Тренировка добавленна в избранные"
                : "Тренировка удалена из избранных"
          });
        });
    },
    likeTraining() {
      if (this.training.is_liked == 1) {
        this.training.is_liked = false;
        this.training.likes = (
          parseInt(this.training.likes, 10) - 1
        ).toString();
      } else {
        this.training.is_liked = true;
        this.training.likes = (
          parseInt(this.training.likes, 10) + 1
        ).toString();
      }

      this.$store.dispatch("likeTraining", {
        id: this.training.id
      });
    },
    readyTraining(withModal = false) {
      this.$store
        .dispatch("setTrainingReady", {
          id: this.training.id,
          pack: this.program.id
        })
        .then(() => {
          this.$root.$emit("page_training__ready_training", this.program.id);

          this.$store.dispatch("getTrainingsCalendar");
          this.$store.dispatch("getTrainingsCompleted");

          if (withModal === true) {
            Swal.fire({
              title: "Отмечена как выполненная!",
              icon: "success",
              confirmButtonColor: "#ef16b0"
            });
          } else {
            this.showMessage();
          }
        })
        .catch(error => {
          const errorMsg = error.response.data.msg;

          if (withModal === true) {
            Swal.fire({
              title: errorMsg,
              text:
                "После просмотра более 50% тренировки, твой прогресс будет автоматически сохранен",
              icon: "warning",
              confirmButtonColor: "#ef16b0"
            });
          }
        });
    },
    confirmDeleteTrainingReady() {
      Swal.fire({
        title: "Вы действительно хотите отменить выполнение этой тренировки?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да",
        confirmButtonColor: "#ef16b0",
        cancelButtonText: "Нет",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        },
      }).then(result => {
        if (result.value) {
          this.deleteTrainingReady(true);
        }
      });
    },
    deleteTrainingReady(withModal = false) {
      this.$store
        .dispatch("deleteTrainingReady", {
          id: this.training.id,
          pack: this.program.id
        })
        .then(() => {
          this.$store.dispatch("getTraining", {
            id: this.training.id,
            pack: this.program.id,
            initLoading: false
          });

          this.$store.dispatch("getTrainingsCalendar");
          this.$store.dispatch("getTrainingsCompleted");

          if (withModal === true) {
            Swal.fire({
              title: "Выполнение успешно отменено!",
              icon: "success",
              confirmButtonColor: "#ef16b0"
            });
          } else {
            this.showMessage();
          }
        })
        .catch(error => {
          const errorMsg = error.response.data.msg;

          if (withModal === true) {
            Swal.fire({
              title: errorMsg,
              icon: "warning",
              confirmButtonColor: "#ef16b0"
            });
          }
        });
    }
  }
};
</script>
