<template>
  <div
    v-if="trainings && program"
    class="training-sidebar"
    :class="{
      'no-subscription': !userSubscriptions && program.tariff == null
    }"
  >
    <div style="width: 100%">
      <div
        ref="training-block-tarinings--header"
        class="training-sidebar__title flex--center"
      >
        План тренировок
        <!--begin::Toolbar-->
        <div class="card-toolbar mb-auto">
          <div
            class="dropdown dropdown-inline"
            data-toggle="tooltip"
            title=""
            data-placement="left"
            data-original-title="Quick actions"
          >
            <a
              href="#"
              class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li class="navi-item cursor-pointer" @click="switchProgram">
                  <p v-if="program.is_selected === true" class="navi-link">
                    <span style="font-weight: 400;" class="navi-text">
                      Завершить
                    </span>
                  </p>
                  <p v-if="program.is_selected === false" class="navi-link">
                    <span style="font-weight: 400;" class="navi-text">
                      Начать
                    </span>
                  </p>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Toolbar-->
      </div>
    </div>
    <div
      class="training-list"
      :style="{
        height: `${height ? height : 'auto'}`
      }"
    >
      <div id="jsid-scrollbar1" class="scrollbar">
        <div class="handle">
          <div class="mousearea"></div>
        </div>
      </div>
      <div id="frame1" class="frame">
        <div class="slidee">
          <div
            class="training-list__item"
            v-for="(trainingItem, indexTraining) of trainings"
            :key="`training-${indexTraining}-${trainingItem.id}`"
            :class="{
              'training-list__item-done': trainingItem.date_last_done,
              'training-list__item-next': trainingItem.id === training.id
            }"
          >
            <div
              v-if="!trainingItem.isRestDay"
              class="cursor-pointer training-list__item--container"
              @click="switchTraining(trainingItem, indexTraining)"
            >
              <div class="training-list__item--training-number">
                {{ indexTraining + 1 }}
              </div>
              <div class="training-list__item--img">
                <img
                  v-if="trainingItem.poster_thumb"
                  :src="trainingItem.poster_thumb"
                />
                <img
                  v-else-if="trainingItem.poster"
                  :src="trainingItem.poster"
                />
                <img
                  v-else
                  :src="`${$APP_URL}${trainingItem.video_local_url}`"
                />
                <div class="training-list__item--duration">
                  {{
                    `${parseInt(trainingItem.duration / 60)}:${
                      trainingItem.duration % 60 >= 10
                        ? trainingItem.duration % 60
                        : trainingItem.duration % 60 > 0
                        ? `0${trainingItem.duration % 60}`
                        : "00"
                    }`
                  }}
                </div>
              </div>
              <div class="training-list__item--info-wr">
                <div class="training-list__item--title-wr">
                  <div class="training-list__item--title">
                    <p>
                      <span style="padding-right: 8px">
                        {{ trainingItem.training_name }}
                      </span>
                      <span style="display: block;">
                        <span
                          :id="`training-list__item--title-${indexTraining}`"
                          :class="
                            `complexity-level complexity-level--${Math.ceil(
                              trainingItem.training_level / 3
                            )}`
                          "
                        ></span>
                        <b-tooltip
                          :target="
                            `training-list__item--title-${indexTraining}`
                          "
                          variant="light"
                          placement="bottom"
                        >
                          {{
                            `Сложность: ${
                              Math.ceil(trainingItem.training_level / 3) === 1
                                ? "легкая"
                                : Math.ceil(trainingItem.training_level / 3) ===
                                  2
                                ? "средняя"
                                : "высокая"
                            }`
                          }}
                        </b-tooltip>

                        <span class="calories-done">
                          {{
                            `${parseInt(
                              (trainingItem.duration / 60) *
                                trainingItem.calories_coefficient
                            )} Ккал`
                          }}
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="training-list__item--tags-wr">
                  <span
                    v-if="
                      trainingItem.training_type &&
                        trainingItem.training_type.name
                    "
                    class="training-list__item--tag first"
                  >
                    {{ trainingItem.training_type.name }}
                  </span>
                  :
                  <span class="training-list__item--tag">
                    <span
                      v-for="(part, indexPart) of trainingItem.parts"
                      :key="
                        `part-${trainingItem.id}-${trainingItem.program_id}-${part.id}`
                      "
                    >
                      {{
                        part.name +
                          (indexPart + 1 === trainingItem.parts.length
                            ? ""
                            : ", ")
                      }}
                    </span>
                  </span>
                </div>
                <div
                  class="training-list__item-data"
                  :class="{
                    'training-list__item-data--done':
                      trainingItem.date_last_done,
                    'training-list__item-data--next-day':
                      trainingItem.is_next_planed == true
                  }"
                >
                  <span v-if="trainingItem.date_last_done">
                    <img
                      style="margin-right: 5px"
                      src="@/assets/img/training/check.svg"
                    />
                    {{
                      `Выполнена: ${getFormattedDate(
                        trainingItem.date_last_done,
                        "DD.MM"
                      )}`
                    }}
                  </span>
                  <span
                    v-else-if="
                      trainingItem.is_next_planed &&
                        trainingItem.is_next_planed == true &&
                        trainingItem.date_planned === tomorrow()
                    "
                  >
                    Завтра
                  </span>
                  <span
                    v-else-if="
                      trainingItem.is_next_planed &&
                        trainingItem.is_next_planed == true &&
                        trainingItem.date_planned === today()
                    "
                  >
                    Сегодня
                  </span>
                  <span
                    style="text-transform: capitalize;"
                    v-else-if="
                      trainingItem.date_planned &&
                        trainingItem.date_planned !== false
                    "
                  >
                    {{
                      `${getFormattedDate(
                        trainingItem.date_planned,
                        "ddd., DD.MM"
                      )}`
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="training-list__item training-list__item-rest">
              <div class="training-list__item--img">
                <img src="@/assets/img/training/relax.jpeg" />
              </div>
              <div class="training-list__item--info-wr">
                <div class="training-list__item--title-wr">
                  <div class="training-list__item--title">День отдыха!</div>
                </div>
                <div class="training-list__item--tags-wr">
                  <span class="training-list__item--tag first">
                    Расслабоение
                  </span>
                  :
                  <span class="training-list__item--tag">на все тело 😉</span>
                </div>
                <div
                  class="training-list__item-data"
                  style="text-transform: capitalize;"
                >
                  {{ getTrainingRelaxDate(trainingItem) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapGetters } from "vuex";
import moment from "moment";
import { getFormattedDate } from "@/assets/js/helpers";

export default {
  name: "FSTrainingTrainings",
  props: {
    trainings: Array,
    training: Object,
    program: Object
  },
  data() {
    return {
      slyElement: null,
      height: null
    };
  },
  computed: {
    ...mapGetters(["userSubscriptions"]),
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    }
  },
  beforeDestroy() {
    this.$root
      .$off("page_training__start_program")
      .$off("event--training-is-loaded");
  },
  mounted() {
    let __self__ = this;

    this.$root
      .$on("page_training__start_program", function() {
        __self__.slyElement.sly("slideTo", 0);
      })
      .$on("event--training-is-loaded", function(height) {
        __self__.setTrainingList(height);
      });
  },
  destroyed() {
    if (this.slyElement && this.slyElement != null) {
      this.slyElement.sly("destroy");
    }
  },
  methods: {
    getFormattedDate,
    getTrainingRelaxDate(training) {
      let formatedDate = "";
      let date = null;

      if (training.date_last_done) {
        date = training.date_last_done;
      } else if (training.date_planned) {
        date = training.date_planned;
      } else {
        date = new Date();
      }

      formatedDate = moment(date).format("ddd., DD.MM");

      return formatedDate;
    },
    switchTraining(training, index) {
      if (training.id != this.training.id) {
        this.$router.push({
          name: "training-pack-pack-training",
          params: {
            pack: this.program.id,
            training: training.id
          }
        });
      }

      this.slyElement.sly("toStart", index);
    },
    tomorrow() {
      const today = new Date();
      const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

      return moment(tomorrow).format("YYYY-MM-DD");
    },
    today() {
      const today = new Date();

      return moment(today).format("YYYY-MM-DD");
    },
    setHeight(height) {
      if (!this.$refs["training-block-tarinings--header"]) {
        this.height = height;

        return;
      }

      this.height = `${height -
        this.$refs["training-block-tarinings--header"].clientHeight -
        window
          .getComputedStyle(
            this.$refs["training-block-tarinings--header"],
            null
          )
          .getPropertyValue("padding-top")
          .replace("px", "") -
        window
          .getComputedStyle(
            this.$refs["training-block-tarinings--header"],
            null
          )
          .getPropertyValue("padding-bottom")
          .replace("px", "") +
        4}px`;
    },
    setTrainingList(height) {
      let __self__ = this;
      let startAtPosition = 0;
      let elementScrollBar = $("#jsid-scrollbar1");
      let elementSly = $("#frame1");

      if (!this.trainings || elementSly.length < 1) {
        return;
      }

      this.setHeight(height);

      this.trainings.forEach(function(training, indexTraining) {
        const index = indexTraining < 1 ? 0 : indexTraining;

        if (
          __self__.$route.params.training &&
          __self__.$route.params.training == training.id
        ) {
          startAtPosition = index;
        } else if (
          training.is_next_planed &&
          !__self__.$route.params.training
        ) {
          startAtPosition = index;
        }
      });

      setTimeout(() => {
        let options = {
          itemNav: "basic",
          easing: "swing",
          scrollBar: elementScrollBar,
          dragHandle: true,
          speed: 300,
          startAt: 0,
          scrollBy: 1,
          scrollTrap: true,
          mouseDragging: 1,
          touchDragging: 1
        };

        __self__.slyElement = elementSly.sly(options).init();
        __self__.slyElement.sly("reload");
        __self__.slyElement.sly("toStart", startAtPosition);
      }, 1000);
    },
    switchProgram() {
      this.$root.$emit("page_training__switch_program", this.program.id);
    }
  }
};
</script>

<style>
  .training-sidebar .training-list__item--img {
    height: 88px;
  }
</style>
