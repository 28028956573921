var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.socialIsMyProfile === true)?_c('div',{staticClass:"fs-ptile profile__invite",attrs:{"id":"jsid-profile-share-compotent"}},[_c('h5',{staticClass:"fs-ptile-title"},[_vm._v("Как добавить друга?")]),_vm._m(0),_c('div',{staticStyle:{"margin-top":"25px"}},[_c('button',{staticStyle:{"margin-bottom":"15px"},on:{"click":function($event){return _vm.copyInvitationLink()}}},[_c('img',{attrs:{"src":require("@/assets/img/profile/copy-btn.svg"),"alt":""}})]),_c('p',[_vm._v("или поделись")]),_c('div',{staticClass:"yandex-share-links"},[_c('yandex-share',{attrs:{"services":[
          'vkontakte',
          'facebook',
          'twitter',
          'telegram',
          'odnoklassniki',
          'viber',
          'whatsapp'
        ],"url":_vm.getInvitationLink(),"counter":""}})],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('p',[_c('span',{staticClass:"list-type"},[_vm._v("1.")]),_vm._v(" Скопируй ссылку с приглашением ")]),_c('p',[_c('span',{staticClass:"list-type"},[_vm._v("2.")]),_vm._v(" Отправь ее своему другу или поделись в соц. сетях ")]),_c('p',[_c('span',{staticClass:"list-type"},[_vm._v("3.")]),_vm._v(" Друг получит бесплатный доступ ко всем программам на 7 дней! ")])])
}]

export { render, staticRenderFns }