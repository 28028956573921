// import { is } from "core-js/core/object";
import $ from "jquery";
import programsService from "../../services/programs.service";

// action types
export const GET_PROGRAMS_LIST = "getProgramsList";
export const GET_PROGRAMS_FILTERS = "getProgramsFilters";
export const GET_PROGRAMS_LIST_BY_FILTER = "getProgramsByFilter";

// mutator types
export const PROGRAMS_IS_FILTERED = "isProgramsFiltered";
export const PROGRAMS_IS_LOADED = "isProgramsLoaded";
export const PROGRAMS_IS_LOADING = "isProgramsLoading";

export const PROGRAMS_FILTERS_IS_LOADED = "isProgramsFiltersLoaded";
export const PROGRAMS_FILTERS_IS_LOADING = "isProgramsFiltersLoading";

export const SWITCH_FILTER_BUTTON = "switchFilterButton";

export default {
  state: {
    buttons: {},
    isMyPrograms: false,
    filters: {
      list: {
        cat_min_level: [],
        goals: [],
        categories: [],
        trainer_name: []
      },
      isLoaded: false,
      isLoading: false
    },
    programs: {
      new: [],
      popular: [],
      all: [],
      list: [],
      temp: [],
      isFiltered: false,
      isLoaded: false,
      isLoading: false
    },
    programsTrial: [],
    _addProgramToCategoryFunc: (
      programsList,
      category,
      program,
      reverse = false
    ) => {
      if (category.code == 'courses' && (program.is_sales_course == 0 || program.special_course == 0)) {
        return false;
      }
      if ((category.code == 'new_programs' || category.code == 'popular_programs') && program.is_sales_course == 1) {
        return false;
      }
      if (!programsList[category.code]) {
        programsList[category.code] = {
          name: category.name,
          code: category.code,
          isAnchorShow:
            category.anchorDontShow && category.anchorDontShow === true
              ? false
              : true,
          hasAnchors: category.hasAnchors ? category.hasAnchors : false,
          programs: []
        };
      }

      if (category.field) {
        programsList[category.code].field = category.field;
      }
      if (category.limit) {
        programsList[category.code].limit = category.limit;
      }

      if (reverse === true) {
        programsList[category.code].programs.unshift(program);
      } else {
        programsList[category.code].programs.push(program);
      }
    },
    _sortProgramsInCategoryFunc: category => {
      if (category.field) {
        category.programs = category.programs.sort((a, b) => {
          return b[category.field] - a[category.field];
        });
      }

      if (category.limit) {
        category.programs.length = category.limit;
      }
    }
  },
  getters: {
    programsFilters: state => state.filters.list,
    programsFiltersButtons: state => state.buttons,
    programsFiltersIsLoaded: state => state.filters.isLoaded,
    programsByCategories: state => {
      const programsByCategoriesSort = state.programs.list
        .slice(2)
        .sort((a, b) => {
          const aSortNumber = a.programs[0].categories.filter(el => el.code === a.code)[0].sort;
          const bSortNumber = b.programs[0].categories.filter(el => el.code === b.code)[0].sort;

          return aSortNumber - bSortNumber;
        })

      return [...state.programs.list.slice(0, 2), ...programsByCategoriesSort];
    },
    programsIsLoaded: state => state.programs.isLoading,
    programsIsLoading: state => state.programs.isLoaded,
    programsIsFiltered: state => state.programs.isFiltered,
    programsTrial: state => state.programsTrial,
    programsNew: state => state.programs.new,
    programsPopular: state => state.programs.popular,
  },
  actions: {
    [GET_PROGRAMS_FILTERS]({ commit }) {
      commit(PROGRAMS_FILTERS_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        programsService.getProgramsFilters(
          response => {
            commit(GET_PROGRAMS_FILTERS, response);

            resolve(response);
          },
          error => {
            commit(PROGRAMS_FILTERS_IS_LOADING, false);
            commit(PROGRAMS_FILTERS_IS_LOADED, false);

            reject(error);
          }
        );
      });
    },
    [GET_PROGRAMS_LIST]({ commit }, filter) {
      commit(PROGRAMS_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        programsService.getProgramsByCategories(
          filter,
          data => {
            data.filter = filter;
            commit(GET_PROGRAMS_LIST, data);

            resolve(data);
          },
          error => {
            commit(PROGRAMS_IS_LOADED, false);
            commit(PROGRAMS_IS_LOADING, false);

            reject(error);
          }
        );
      });
    },
    getProgramsTrial({ commit }) {
      return new Promise((resolve, reject) => {
        programsService.getProgramsTrial(
          data => {
            commit('SET_PROGRAMS_TRIAL', data.programs);

            resolve(data);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    [GET_PROGRAMS_LIST_BY_FILTER]({ commit }, filter) {
      commit(GET_PROGRAMS_LIST_BY_FILTER, filter);
    },
    [SWITCH_FILTER_BUTTON]({ commit }, filterName) {
      commit(SWITCH_FILTER_BUTTON, filterName);
    }
  },
  mutations: {
    [GET_PROGRAMS_FILTERS](state, filters) {
      state.filters.list = filters;
      state.filters.isLoaded = true;
      state.filters.isLoading = false;
    },
    [GET_PROGRAMS_LIST](state, data) {
      const customCategory = [
        "popular_programs",
        "new_programs",
        "my_programs"
      ];

      let categoriesList = {};
      let programsByCategories = [];

      state.isMyPrograms = data.filter.my_programs;
      state.programs.isFiltered = data.isFiltered;
      state.programs.all = data.programs;
      state.programs.new = data.new;
      state.programs.popular = data.popular;

      if (state.programs.isFiltered === true) {
        $.each(state.programs.all, (index, program) => {
          state._addProgramToCategoryFunc(
            categoriesList,
            {
              name: "none",
              code: "none"
            },
            program
          );
        });

        // eslint-disable-next-line no-unused-vars
        $.each(categoriesList, (index, category) => {
          programsByCategories.push(categoriesList[index]);
        });
      } else if (state.isMyPrograms === true) {
        $.each(state.programs.all, (index, program) => {
          state._addProgramToCategoryFunc(
            categoriesList,
            {
              name: "Мои программы",
              code: "my_programs"
            },
            program
          );
        });

        $.each(categoriesList, (index, category) => {
          state._sortProgramsInCategoryFunc(category);
          if ($.inArray(category.code, customCategory) < 0) {
            programsByCategories.push(categoriesList[index]);
          }
        });

        $.each(customCategory, (index, category) => {
          if (categoriesList && categoriesList[category]) {
            programsByCategories.unshift(categoriesList[category]);
          }
        });

        if (programsByCategories.length > 0) {
          programsByCategories[0].programs = programsByCategories[0].programs.sort(
            function(a, b) {
              return a.program_started_id < b.program_started_id;
            }
          );
        }
      } else {
        $.each(state.programs.all, (index, program) => {

          $.each(program.categories, (index, category) => {
            state._addProgramToCategoryFunc(categoriesList, category, program);
          });
        });

        $.each(categoriesList, (index, category) => {
          state._sortProgramsInCategoryFunc(category);
          if ($.inArray(category.code, customCategory) < 0) {
            programsByCategories.push(categoriesList[index]);
          }
        });

        $.each(customCategory, (index, category) => {
          if (categoriesList && categoriesList[category]) {
            programsByCategories.unshift(categoriesList[category]);
          }
        });
      }

      state.programs.list = programsByCategories;
      state.programs.temp = programsByCategories;
    },
    [GET_PROGRAMS_LIST_BY_FILTER](state, filter) {
      let programsByCategories = JSON.parse(JSON.stringify(state.programs.all));
      let programsByCategoriesArray = [];
      let categoriesList = {};
      let isEmptyFilters = true;

      let programs = programsByCategories.filter(program => {
        let isNeedToAdd = true;

        $.each(filter, (filterName, filterList) => {
          let isNeedToAddItem = false;
          let isEmptyFilter = filterList.length < 1 ? true : false;

          isEmptyFilters =
            isEmptyFilters !== false && filterList.length < 1 ? true : false;

          if (isEmptyFilter === true) {
            return;
          }

          switch (filterName) {
            case "goals":
            case "categories":
              $.each(program[filterName], (indexP, pItem) => {
                $.each(filterList, (indexF, fId) => {
                  if (fId == pItem.id) {
                    isNeedToAddItem = true;
                  }
                });
              });

              break;
            case "cat_min_level":
            case "trainer_name":
              $.each(filterList, (indexF, fItem) => {
                if (fItem == program[filterName]) {
                  isNeedToAddItem = true;
                }
              });

              break;
            default:
              break;
          }

          if (
            isEmptyFilter === false &&
            isNeedToAddItem === false &&
            isNeedToAdd !== false
          ) {
            isNeedToAdd = false;
          }
        });

        if (isNeedToAdd === true) {
          return true;
        }

        return false;
      });

      if (isEmptyFilters === true) {
        state.programs.isFiltered = false;
        state.programs.list = state.programs.temp;

        return;
      }

      $.each(programs, (index, program) => {
        state._addProgramToCategoryFunc(
          categoriesList,
          {
            name: "none",
            code: "none"
          },
          program
        );
      });

      // eslint-disable-next-line no-unused-vars
      $.each(categoriesList, (index, category) => {
        programsByCategoriesArray.push(category);
      });

      state.programs.list = programsByCategoriesArray;
      state.programs.isFiltered = true;
    },
    [PROGRAMS_FILTERS_IS_LOADED](state, isLoaded) {
      state.filters.isLoaded = isLoaded;
    },
    [PROGRAMS_FILTERS_IS_LOADING](state, isLoading) {
      state.filters.isLoading = isLoading;
    },
    [PROGRAMS_IS_LOADED](state, isLoaded) {
      state.programs.isLoaded = isLoaded;
    },
    [PROGRAMS_IS_LOADING](state, isLoading) {
      state.programs.isLoading = isLoading;
    },
    [SWITCH_FILTER_BUTTON](state, filterName) {
      let buttons = JSON.parse(JSON.stringify(state.buttons));

      if (
        filterName &&
        (!buttons[filterName] || buttons[filterName] == false)
      ) {
        buttons[filterName] = true;
      } else if (filterName && buttons[filterName] == true) {
        buttons[filterName] = false;
      }

      // eslint-disable-next-line no-unused-vars
      $.each(buttons, (index, value) => {
        if (index != filterName || !filterName) {
          buttons[index] = false;
        }
      });

      state.buttons = buttons;
    },
    SET_PROGRAMS_TRIAL(state, programs) {
      state.programsTrial = programs;
    }
  }
};
