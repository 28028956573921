import settings from "../../settings";
import instance from "./instance.service";
import { getAuthHeader } from "../services/auth.service";

const basePath = `${settings.API_URL}`;

export default {
  getSettings(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/settings`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getUser(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/show/`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getUserInviteCounters(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/invite/show`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateUser(data, successCallback, errorCallback) {
    instance
      .put(`${basePath}user`, data, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateUserAvatar(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/upload_avatar`, data, {
        headers: getAuthHeader("multipart/form-data")
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  updateUserPassword(data, successCallback, errorCallback) {
    if (data.new_password !== data.new_password_confirm) {
      errorCallback("Пароли не совпадают");

      return;
    }

    instance
      .post(`${basePath}user/password`, data, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  }
};
