import userService from "@/core/services/user.service";
import settings from "@/settings";

export default {
  state: {
    user: {},
    userInviteCounters: {
      purchases_count: 0,
      users_registered_count: 0
    },
    settings: {
      checkout_redirect_from_cabinet: "subscribe"
    },
    appURL: settings.APP_URL
  },
  getters: {
    autoComplite: state => {
      if (
        state.user.auto_complite_training &&
        state.user.auto_complite_training == 1
      ) {
        return true;
      }

      return false;
    },
    userData: state => state.user,
    userInviteCounters: state => state.userInviteCounters,
    siteSettings: state => state.settings,
    siteAppURL: state => state.appURL,
    isDefaultYoutube: state => {
      if (
        state.user.show_youtube_player &&
        state.user.show_youtube_player == 1
      ) {
        return true;
      }

      return false;
    }
  },
  actions: {
    getUser({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getUser(
          data => {
            commit("set_user", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    getUserInviteCounters({ commit }) {
      return new Promise((resolve, reject) => {
        userService.getUserInviteCounters(
          data => {
            commit("set_user_invite_counters", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    updateUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        // commit("auth_request");

        userService.updateUser(
          user,
          data => {
            commit("update_user_success");
            resolve(data);
          },
          (e) => {
            reject(e);
          }
        );
      });
    },
    updateUserAvatar({ commit }, formData) {
      return new Promise((resolve, reject) => {
        // commit("auth_request");

        userService.updateUserAvatar(
          formData.formData,
          data => {
            commit("set_user_avatar", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    },
    updateUserPassword({ commit }, user) {
      return new Promise((resolve, reject) => {
        // commit("auth_request");

        userService.updateUserPassword(
          user,
          data => {
            commit("set_user_password", data);
            resolve(data);
          },
          error => {
            if (error.response && error.response.data) {
              reject(error.response.data.data);
            }
            reject(error);
          }
        );
      });
    },
    getSettings({ commit }) {
      return new Promise((resolve, reject) => {
        // commit("auth_request");

        userService.getSettings(
          data => {
            commit("set_settings", data);
            resolve(data);
          },
          () => {
            reject();
          }
        );
      });
    }
  },
  mutations: {
    set_user(state, data) {
      state.user = data.data;
    },
    set_user_invite_counters(state, data) {
      state.userInviteCounters = data.data;
    },
    set_user_avatar(state, data) {
      state.status = data.data;
    },
    set_user_password(state, data) {
      state.status = data.data;
    },
    set_settings(state, data) {
      state.settings = data.data;
    },
    update_user_success(state) {
      const user = state.user;
      state.user = user;
    }
  }
};
