import settings from "../../settings";
import instance from "./instance.service";

import { getAuthHeader } from "./auth.service";

// import { GET_PROGRAMS_FILTERS } from "@/core/services/store/programs.module";

const basePath = `${settings.API_URL}`;

export default {
  getProgramsFilters(successCallback, errorCallback) {
    instance
      .get(`${basePath}user/programs/filters`, { headers: getAuthHeader() })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  switchProgramMyProgram(data, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/training/switch`, data, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getProgramsByCategories(filters, successCallback, errorCallback) {
    instance
      .post(`${basePath}user/programs/filter`, filters, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getProgramsTrial(successCallback, errorCallback) {
    instance
      .post(`${basePath}user/programs/trial`, {}, {
        headers: getAuthHeader()
      })
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getProgramsByCategoriesUser(successCallback, errorCallback) {
    instance
      .post(
        `${basePath}user/programs/filter`,
        {
          categories: ["pump_up_muscles", "stretch", "lose_weight", "health"]
        },
        {
          headers: getAuthHeader()
        }
      )
      .then(resp => {
        if (resp && resp.data) {
          successCallback(resp.data.data);
        }
      })
      .catch(err => {
        errorCallback(err);
      });
  },
  getAuthHeader(type) {
    return {
      Authorization: `Bearer ${window.localStorage.getItem(settings.ID_TOKEN)}`,
      "Content-Type": type
    };
  }
};
