<template>
  <!--begin::Card-->
  <div
    v-if="course && indexCourse >= 0"
    class="card card-custom gutter-b card-stretch program-my"
  >
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Section-->
      <div class="d-flex program-my--header">
        <!--begin::Pic-->
        <router-link
          :to="`/training/${course.id}`"
          class="program-my--header--image"
          :id="`couse-${course.id}`"
        >
          <img :src="`${course.pic}`" alt="image" />
        </router-link>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="d-flex flex-column mr-auto program-my--header--txt">
          <!--begin: Title-->
          <router-link
            class="font-weight-bolder font-size-h3 text-dark program-my--header--title"
            style="line-height: 27px"
            :to="`/training/${course.id}`"
          >
            {{ course.name }}
          </router-link>
          <span class="font-size-h4 text-dark mb-5">
            {{ course.trainer_name }}
          </span>
          <!--end::Title-->
          <span
            class="program--description text-dark"
            v-html="course.additional_description"
          ></span>
          <!-- <span
            class="program--description text-dark"
            v-html="course.description"
          ></span> -->
        </div>
        <!--end::Info-->
        <!--begin::Toolbar-->
        <div class="card-toolbar mb-auto">
          <div
            class="dropdown dropdown-inline"
            data-toggle="tooltip"
            title=""
            data-placement="left"
            data-original-title="Quick actions"
          >
            <a
              href="#"
              class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ki ki-bold-more-hor"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <!--begin::Navigation-->
              <ul class="navi navi-hover">
                <li class="navi-item">
                  <router-link class="navi-link" :to="`/training/${course.id}`">
                    <span class="navi-text">
                      Открыть
                    </span>
                  </router-link>
                </li>
                <li
                  class="navi-item cursor-pointer"
                  @click="switchCourseMyCourse()"
                >
                  <p class="navi-link">
                    <span class="navi-text">Завершить</span>
                  </p>
                </li>
              </ul>
              <!--end::Navigation-->
            </div>
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Section-->

      <!--begin::Content-->
      <div class="d-flex flex-wrap mt-10 program-my--footer-content">
        <div class="d-flex flex-wrap program-my--info-wr">
          <div class="d-flex flex-wrap program-info">
            <span
              class="d-block font-weight-boldest mb-4 program-info--title"
              style="width: 100%"
            >
              Релиз
            </span>
            <span
              class="btn-light-pink font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
            >
              {{ getFormattedDate(course.date_create, "DD.MM.YYYY") }}
            </span>
          </div>
          <div class="col-divider"></div>
          <div class="d-flex flex-wrap program-info">
            <span
              class="d-block font-weight-boldest mb-4 program-info--title"
              style="width: 100%"
            >
              Проходят
            </span>
            <span
              class="btn-light-green font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
            >
              {{ course.count_last_30_days_done_trainings }} чел.
            </span>
          </div>
          <div class="col-divider"></div>
          <div class="d-flex flex-wrap program-info">
            <span
              class="d-block font-weight-boldest mb-4 program-info--title"
              style="width: 100%"
            >
              Пол (ж/м)
            </span>
            <span
              class="btn-light-orange font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
            >
              {{ course.gender_relation }}
            </span>
          </div>
        </div>
        <!--begin::Progress-->
        <div class="flex-row-fluid program-my--progres">
          <span class="d-block font-weight-boldest mb-4">
            Твой прогресс
          </span>
          <div class="d-flex align-items-center pt-2">
            <div class="progress progress-xs mt-2 mb-2 w-100">
              <div
                class="progress-bar"
                role="progressbar"
                :style="`width: ${getProgress(course.progress)};`"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <span class="ml-3 font-weight-bolder">
              {{ getProgress(course.progress) }}
            </span>
          </div>
          <!--end::Progress-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Body-->

    <!--begin::Footer-->
    <div class="card-footer">
      <div :id="`jsid-scrollbar-${indexCourse}-${course.id}`" class="scrollbar">
        <div class="handle">
          <div class="mousearea"></div>
        </div>
      </div>
      <div :id="`frame-${indexCourse}-${course.id}`" class="frame">
        <ul class="slidee">
          <li
            v-for="(training, indexTraining) of course.trainings"
            :key="
              `training-${indexCourse}-${course.id}-${indexTraining}-${training.id}`
            "
            class="training-slider-block"
          >
            <div
              v-if="training.date_last_done"
              class="training-slider-block--wr training-done"
            >
              <div v-if="course.trainings" class="training-slider-block--title">
                {{ indexTraining + 1 }}/{{ course.trainings.length }}
              </div>
              <div class="training-slider-block--iframe" style="">
                <div
                  :id="`training-video-by-id-${indexCourse}`"
                  class="training embed-responsive"
                  :ref="`js-embed-responsive-training-${training.id}`"
                ></div>
              </div>
              <div class="training-slider-block--img">
                <router-link
                  class="navi-link"
                  :to="`/training/${course.id}/${training.id}`"
                >
                  <b-img-lazy
                    v:bind="mainProps"
                    :src="`${$APP_URL}storage/app/previews/${training.id}.jpg`"
                  ></b-img-lazy>
                </router-link>
              </div>
              <div class="trtaining-duration">
                {{
                  `${parseInt(training.duration / 60)}:${
                    training.duration % 60 >= 10
                      ? training.duration % 60
                      : training.duration % 60 > 0
                      ? `0${training.duration % 60}`
                      : "00"
                  }`
                }}
              </div>
              <div class="trtaining-date trtaining-date--done">
                {{ getFormattedDate(training.date_last_done, "ddd. DD.MM") }}
              </div>
              <div v-if="training.date_planned" class="trtaining-date">
                {{
                  training.next_training_description +
                    getFormattedDate(training.date_planned, "ddd. DD.MM")
                }}
              </div>
            </div>
            <div v-else class="training-slider-block--wr training-not-done">
              <div v-if="course.trainings" class="training-slider-block--title">
                {{ indexTraining + 1 }}/{{ course.trainings.length }}
              </div>
              <div class="training-slider-block--iframe" style="">
                <div
                  :id="`training-video-by-id-${indexCourse}`"
                  class="training embed-responsive"
                  :ref="`js-embed-responsive-training-${training.id}`"
                ></div>
              </div>
              <div class="training-slider-block--img">
                <router-link
                  class="navi-link"
                  :to="`/training/${course.id}/${training.id}`"
                >
                  <b-img-lazy
                    v:bind="mainProps"
                    :src="`${training.poster_thumb}`"
                  ></b-img-lazy>
                </router-link>
              </div>

              <div class="trtaining-duration">
                {{
                  `${parseInt(training.duration / 60)}:${
                    training.duration % 60 >= 10
                      ? training.duration % 60
                      : training.duration % 60 > 0
                      ? `0${training.duration % 60}`
                      : "00"
                  }`
                }}
              </div>
              <div v-if="training.date_planned" class="trtaining-date">
                <div
                  :class="{
                    'next-day': training.is_next_planed
                  }"
                >
                  {{
                    `${training.next_training_description} ${getFormattedDate(
                      training.date_planned,
                      training.next_training_description
                        ? "DD.MM"
                        : "ddd. DD.MM"
                    )}`
                  }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Swal from "sweetalert2";
import { getYoutubeId, getFormattedDate } from "@/assets/js/helpers";

export default {
  name: "FSCourseItemMy",
  props: {
    course: Object,
    indexCourse: Number
  },
  data() {
    return {
      slyElement: null,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 400,
        class: "my-5"
      }
    };
  },
  mounted() {
    this.setTrainingList();
  },
  destroyed() {
    if (this.slyElement && this.slyElement != null) {
      this.slyElement.sly("destroy");
    }
  },
  methods: {
    getProgress(progress) {
      let result = "0%";

      if (progress && progress != 0) {
        result = progress.split("/");
        result = `${parseInt((result[0] / result[1]) * 100)}%`;
      }

      return result;
    },
    setTrainingList() {
      let __self__ = this;
      let startAtPosition = 0;
      let elementScrollBar = $(
        `#jsid-scrollbar-${this.indexCourse}-${this.course.id}`
      );
      let elementSly = $(`#frame-${this.indexCourse}-${this.course.id}`);

      if (!this.course || !this.course.trainings || elementSly.length < 1) {
        return;
      }

      this.course.trainings.forEach(function(training, indexTraining) {
        const index = indexTraining < 1 ? 0 : indexTraining - 1;

        if (training.is_next_planed) {
          startAtPosition = index;
        }
      });

      setTimeout(() => {
        let options = {
          horizontal: true,
          itemNav: "basic",
          easing: "swing",
          scrollBar: elementScrollBar,
          dragHandle: true,
          speed: 300,
          startAt: 0,
          scrollBy: 2,
          scrollTrap: true,
          mouseDragging: 1,
          touchDragging: 1
        };

        __self__.slyElement = elementSly.sly(options).init();
        __self__.slyElement.sly("reload");
        __self__.slyElement.sly("toStart", startAtPosition);
        this.$Lazyload.lazyLoadHandler();
      }, 100);
    },
    imageTriningPreview(training) {
      let imageTriningPreview = "";

      if (training && training.video) {
        imageTriningPreview = `
          https://img.youtube.com/vi/${getYoutubeId(
            training.video
          )}/maxresdefault.jpg
        `;
      }

      return imageTriningPreview;
    },
    switchCourseMyCourse() {
      Swal.fire({
        title: "Вы уверены, что хотите завершить программу?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Завершить программу",
        cancelButtonText: "Отменить",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        },
        confirmButtonColor: "#EF16B0"
      }).then(result => {
        if (result.value && this.course.is_selected == true) {
          window.carrotquest.track(`Завершил программу {название}`);
        } else if (result.value && this.course.is_selected == false) {
          window.carrotquest.track(`Начал программу {название}`);
        }

        if (result.value) {
          this.$store
            .dispatch("switchProgramMyProgram", {
              pack_id: this.course.id
            })
            .then(() => {
              this.$root.$emit("reloadCoursesPage", "loaded");
            });
        }
      });
    },
    getYoutubeId,
    getFormattedDate
  },
  watch: {
    course() {
      this.setTrainingList();
    }
  }
};
</script>
