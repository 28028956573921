<template>
  <div class="fs-ptile">
    <div class="fs-ptiles-tape">
      <div class="training-comment">
        <div class="training-comment__title">Оставить отзыв</div>
        <textarea
          id="kt_autosize_1"
          class="training-comment__textarea"
          v-model="comment"
          rows="8"
        ></textarea>
        <div class="flex flex--center flex--wrap flex--sc-end">
          <button class="btn btn--succses" @click="sendComment">
            Отправить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ADD_COMMENT } from "@/core/services/store/comments.module";

export default {
  name: "FSComment",
  props: {
    commentObject: Object,
    type: Number
  },
  data() {
    return {
      comment: ""
    };
  },
  computed: {},
  mounted() {},
  methods: {
    sendComment() {
      this.$store
        .dispatch(ADD_COMMENT, {
          training_id: this.commentObject.id,
          type: this.type,
          parent: null,
          content: this.comment
        })
        .then(() => {
          this.comment = "";

          this.$root.$emit("page_comments__send_comment", {
            id: this.commentObject.id,
            type: this.type
          });
        });
    }
  }
};
</script>
