<template>
  <div class="programs">
    <div class="class">
      <NoticeSubscriptionRenewal v-if="!myProgramsPage && !userSubscriptions"/>

      <div v-if="myProgramsPage === false" class="page-head">
        <div class="page-title" style="display: none">
          <h2>Программы</h2>
        </div>

        <FSProgramFilter
          :key="`program-item-key-component-filter`"
        ></FSProgramFilter>
      </div>
      <div class="page-body">
        <NoticeSubscriptionRenewal v-if="myProgramsPage && !userSubscriptions"/>

        <div
          v-if="programsByCategories.length === 0 && myProgramsPage === true"
          class="fs-ptile--programs-my fs-ptile--wrap"
        >
          <div>
            <h5 class="fs-ptile-title mb-8 mt-8 mr-8 ml-8" style="width: 100%">
              Тут будут твои программы
            </h5>
          </div>
          <div class="fs-ptile-line"></div>
          <div class="mb-8 mt-8 mr-8 ml-8" style="width: 100%">
            Программы, которые ты проходишь появляются в этом разделе после
            нажатия на кнопку "Начать программу"
          </div>
          <div class="mb-8 ml-8">
            <router-link to="/programs" v-slot="{ href, navigate }">
              <a :href="href" class="btn btn-success mr-2" @click="navigate">
                Выбрать программу
              </a>
            </router-link>
          </div>
        </div>
        <div
          v-if="!programsIsFiltered && programsTrial.length && myProgramsPage === false"
          class="mt-0"
        >
          <h3
            class="programs-categories__name"
          >
            Доступно тебе
          </h3>
          <div>
            <div class="catalog">
              <div
                id="frame-trial"
                class="frame"
              >
                <ul class="slidee">
                  <li
                    v-for="(program, indexProgram) of programsTrial"
                    :key="
                      `program-item-key-${indexProgram}-${program.id}`
                    "
                    class="catalog--item"
                    @click="goToProgram(program)"
                  >
                    <FSProgramItem
                      :indexProgram="indexProgram"
                      :indexCategory="1"
                      :userSubscriptions="userSubscriptions"
                      :program="program"
                      :isTrial="true"
                      :key="
                        `program-item-key-component-${indexProgram}-${program.id}`
                      "
                    ></FSProgramItem>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!programsIsFiltered && myProgramsPage === false"
          class="mt-0"
        >
          <h3
            class="programs-categories__name"
          >
            Новинки
          </h3>
          <div>
            <div class="catalog">
              <div
                id="frame-new"
                class="frame"
              >
                <ul class="slidee">
                  <li
                    v-for="(program, indexProgram) of programsNew"
                    :key="
                      `program-item-key-${indexProgram}-${program.id}`
                    "
                    class="catalog--item"
                    @click="goToProgram(program)"
                  >
                    <FSProgramItem
                      :indexProgram="indexProgram"
                      :indexCategory="1"
                      :userSubscriptions="userSubscriptions"
                      :program="program"
                      :isTrial="false"
                      :key="
                        `program-item-key-component-${indexProgram}-${program.id}`
                      "
                    ></FSProgramItem>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!programsIsFiltered && myProgramsPage === false"
          class="mt-0"
        >
          <h3
            class="programs-categories__name"
          >
            Популярное в этом месяце
          </h3>
          <div>
            <div class="catalog">
              <div
                id="frame-popular"
                class="frame"
              >
                <ul class="slidee">
                  <li
                    v-for="(program, indexProgram) of programsPopular"
                    :key="
                      `program-item-key-${indexProgram}-${program.id}`
                    "
                    class="catalog--item"
                    @click="goToProgram(program)"
                  >
                    <FSProgramItem
                      :indexProgram="indexProgram"
                      :indexCategory="1"
                      :userSubscriptions="userSubscriptions"
                      :program="program"
                      :isTrial="false"
                      :key="
                        `program-item-key-component-${indexProgram}-${program.id}`
                      "
                    ></FSProgramItem>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!programsIsFiltered && myProgramsPage === false">
          <h3 class="mb-8">По направлениям</h3>
          <span
            v-for="(anchor, indexAnchor) of programsByCategories"
            :key="`program-item-key-anchors-${indexAnchor}`"
          >
            <span
              v-if="anchor.isAnchorShow === true"
              class="btn btn--filter"
              @click="scrollToAchor(anchor.code)"
            >
              {{ anchor.name }}
            </span>
          </span>
        </div>
        <div
          v-if="programsByCategories.length === 0 && myProgramsPage === false"
          class="col-xs-12 mt-11"
        >
          По заданным вами критериям ничего не найдено, измени, пожалуйста,
          {{ " " }}
          настройки поиска
        </div>
        <div
          v-else-if="programsByCategories.length > 0"
          v-for="(category, indexCategory) in programsByCategories"
          :key="
            `programs-categories-${
              myProgramsPage === true ? 'my' : ''
            }-${indexCategory}`
          "
          :class="{ 'mt-0': indexCategory === 0 }"
        >
          <h3
            :id="`anchor-${category.code}`"
            class="programs-categories__name"
            v-if="
              !programsIsFiltered &&
                category &&
                myProgramsPage === false &&
                category.code !== 'none'
            "
          >
            {{ category.name }}
          </h3>
          <div v-if="myProgramsPage === true">
            <div class="row" v-if="category">
              <div
                v-for="(program, indexProgram) of category.programs"
                :key="
                  `program-item-key-${indexCategory}-${indexProgram}-${program.id}`
                "
                class="col-xl-6"
                @click="goToProgram(program)"
              >
                <FSProgramItemMy
                  :indexProgram="indexProgram"
                  :userSubscriptions="userSubscriptions"
                  :program="program"
                  :key="
                    `program-item-key-component-${indexCategory}-${indexProgram}-${program.id}`
                  "
                ></FSProgramItemMy>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="catalog" v-if="category">
              <div
                v-if="programsIsFiltered === false"
                :id="`frame-${indexCategory}-${category.code}`"
                class="frame"
              >
                <ul class="slidee">
                  <li
                    v-for="(program, indexProgram) of category.programs"
                    :key="
                      `program-item-key-${indexCategory}-${indexProgram}-${program.id}`
                    "
                    class="catalog--item"
                    @click="goToProgram(program)"
                  >
                    <FSProgramItem
                      :indexProgram="indexProgram"
                      :indexCategory="indexCategory"
                      :userSubscriptions="userSubscriptions"
                      :program="program"
                      :key="
                        `program-item-key-component-${indexCategory}-${indexProgram}-${program.id}`
                      "
                    ></FSProgramItem>
                  </li>
                </ul>
              </div>
              <div v-else>
                <ul class="flex flex-wrap catalog--on-filter">
                  <li
                    v-for="(program, indexProgram) of category.programs"
                    :key="
                      `program-item-key-${indexCategory}-${indexProgram}-${program.id}`
                    "
                    class="catalog--item"
                    @click="goToProgram(program)"
                  >
                    <FSProgramItem
                      :indexProgram="indexProgram"
                      :indexCategory="indexCategory"
                      :userSubscriptions="userSubscriptions"
                      :program="program"
                      :key="
                        `program-item-key-component-${indexCategory}-${indexProgram}-${program.id}`
                      "
                    ></FSProgramItem>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="remodal" data-remodal-id="buy-training">
      <div class="modalr">
        <div class="modalr-close" data-remodal-action="close"></div>
        <div class="modalr-content">
          <h3>
            Чтобы выбрать программу тренировок необходимо продлить подписку
          </h3>
          <a
            :href="`${$APP_URL}${siteSettings.checkout_redirect_from_cabinet}`"
            class="btn btn-success btn-large"
          >
            Продлить
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "remodal/dist/remodal.min";

import FSProgramFilter from "@/components/PagePartials/programs/Filter.vue";
import FSProgramItem from "@/components/PagePartials/programs/Item/index.vue";
import FSProgramItemMy from "@/components/PagePartials/programs/Item/My.vue";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_PROGRAMS_LIST } from "@/core/services/store/programs.module";
import { getYoutubeId, getFormattedDate } from "@/assets/js/helpers";

window.jQuery = $;

export default {
  name: "PagePrograms",
  components: {
    FSProgramFilter,
    FSProgramItem,
    FSProgramItemMy,
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      slyElements: [],
      myProgramsPage: false,
      modalBuyTraining: null,
      modalMoreSubscription: null,
      programUrl: null
    };
  },
  computed: {
    programsByCategories() {
      return this.$store.getters.programsByCategories;
    },
    programsIsFiltered() {
      return this.$store.getters.programsIsFiltered;
    },
    programsTrial() {
      return this.$store.getters.programsTrial;
    },
    programsPopular() {
      return this.$store.getters.programsPopular;
    },
    programsNew() {
      return this.$store.getters.programsNew;
    },
    user() {
      return this.$store.getters.userData;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
    isLoading() {
      return this.$store.getters.programsIsLoading;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    }
  },
  methods: {
    getYoutubeId,
    getFormattedDate,
    getPrograms() {
      this.$store
        .dispatch(GET_PROGRAMS_LIST, {
          my_programs: this.myProgramsPage
        })
        .then(async () => {
          if (this.user.has_trial) {
            await this.$store.dispatch('getProgramsTrial');
          }
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");

          this.setSlyForPrograms();
          this.emitQuestionnaireModal();

          return this.$store.dispatch("getUserSubscriptions");
        });
    },
    setSlyForPrograms() {
      let __self__ = this;

      if (
        !this.programsByCategories ||
        this.programsByCategories === [] ||
        this.myProgramsPage === true
      ) {
        return;
      }

      setTimeout(() => {
        __self__.slyElements = [];

        __self__.programsByCategories.forEach(function(category, index) {
          let elementSly = $(`#frame-${index}-${category.code}`);
          let options = {
            horizontal: true,
            itemNav: "basic",
            easing: "swing",
            dragHandle: true,
            speed: 300,
            scrollHijack: 500,
            scrollBy: 2,
            scrollTrap: true,
            mouseDragging: 1,
            touchDragging: 1
          };

          if (elementSly.length < 1) {
            return;
          }

          __self__.slyElements[category.code] = elementSly;

          elementSly.sly(options).init();
        });

        if (this.programsTrial.length) {
          let elementSly = $('#frame-trial');
          let options = {
            horizontal: true,
            itemNav: "basic",
            easing: "swing",
            dragHandle: true,
            speed: 300,
            scrollHijack: 500,
            scrollBy: 2,
            scrollTrap: true,
            mouseDragging: 1,
            touchDragging: 1
          };

          if (elementSly.length < 1) {
            return;
          }

          __self__.slyElements['trial'] = elementSly;

          elementSly.sly(options).init();
        }
        if (this.programsNew?.length) {
          let elementSly = $('#frame-new');
          let options = {
            horizontal: true,
            itemNav: "basic",
            easing: "swing",
            dragHandle: true,
            speed: 300,
            scrollHijack: 500,
            scrollBy: 2,
            scrollTrap: true,
            mouseDragging: 1,
            touchDragging: 1
          };

          if (elementSly.length < 1) {
            return;
          }

          __self__.slyElements['trial'] = elementSly;

          elementSly.sly(options).init();
        }
        if (this.programsPopular?.length) {
          let elementSly = $('#frame-popular');
          let options = {
            horizontal: true,
            itemNav: "basic",
            easing: "swing",
            dragHandle: true,
            speed: 300,
            scrollHijack: 500,
            scrollBy: 2,
            scrollTrap: true,
            mouseDragging: 1,
            touchDragging: 1
          };

          if (elementSly.length < 1) {
            return;
          }

          __self__.slyElements['trial'] = elementSly;

          elementSly.sly(options).init();
        }
      }, 200);
    },
    goToProgram(program) {
      let available =
        this.userSubscriptions || program.tariff !== null || program.is_trial && this.user.has_trial ? true : false;

      if (available === false) {
        this._openBuyTraining(program.url);
      } else if (program.hide_for_user == true) {
        return;
      } else if (available === true && this.myProgramsPage === false) {
        this.$router.push(`/training/${program.id}`);
      }
    },
    scrollToAchor(anchorCode) {
      let heightElementSubheader = $("#kt_subheader").height();
      let heightElementHeader = $("#kt_header").height();
      let heightElementAnchorH3 = $(`#anchor-${anchorCode}`).height();

      $([document.documentElement, document.body]).animate(
        {
          scrollTop:
            $(`#anchor-${anchorCode}`).offset().top -
            heightElementSubheader -
            heightElementHeader -
            heightElementAnchorH3 -
            15
        },
        2000
      );
    },
    emitQuestionnaireModal() {
      this.$root.$emit("pageLoaded", "loaded");
    },
    _openBuyTraining(url) {
      this.programUrl = url;

      this.$nextTick(() => {
        this.modalBuyTraining.open();
      });
    },
    _setMyProgramsPage() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

      if (this.$route.name === "programs-my") {
        this.myProgramsPage = true;

        this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Программы", route: "/programs" },
          { title: "Мои" }
        ]);
      } else {
        this.myProgramsPage = false;
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Программы" }]);
      }

      this.modalBuyTraining = $("[data-remodal-id='buy-training']").remodal({
        hashTracking: false
      });

      this.getPrograms();
    }
  },
  mounted() {
    let __self__ = this;

    this._setMyProgramsPage();

    this.$root.$on("reloadProgramsPage", function() {
      __self__._setMyProgramsPage();
    });
  },
  watch: {
    $route() {
      this._setMyProgramsPage();
    },
    // eslint-disable-next-line no-unused-vars
    programsIsFiltered(to, from) {
      if (to === false) {
        this.setSlyForPrograms();
      } else if (to === true && this.slyElements.length > 0) {
        //  this.slyElements.forEach(sly => {
        //    sly.destroy();
        //  });

        this.slyElements = [];
      }
    }
  }
};
</script>

<style lang="scss">
.card-footer {
  .frame {
    overflow: hidden;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      zoom: 1;

      li {
        float: left;
        height: 100%;
        margin: 0 10px;
        width: 114px;
        min-width: 114px;
      }
    }

    .slidee {
      margin: 0 -10px;
      padding: 0;
      padding-top: 14px;
      height: 100%;
      list-style: none;
    }
  }

  .scrollbar {
    margin: 0 0 1em 0;
    height: 2px;
    background: #ccc;
    line-height: 0;

    .handle {
      width: 51px;
      height: 100%;
      background: #292a33;
      cursor: pointer;

      .mousearea {
        position: absolute;
        top: -5px;
        left: 0;
        width: 51px;
        height: 12px;
        background: #c4c4c4;
      }
    }
  }
}
</style>
