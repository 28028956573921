import Vue from "vue";
import Notifications from "vue-notification";
import VueYoutube from "vue-youtube";
import VModal from "vue-js-modal";
import App from "./App.vue";
import YandexShare from "@cookieseater/vue-yandex-share";
import router from "./router";
import settings from "./settings";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import $ from "jquery";
window.jQuery = $;

import "@/assets/js/sly.js";
import VueLazyload from "vue-lazyload";
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import { checkOldBrowser } from "@/assets/js/helpers";

checkOldBrowser();

// API service init
ApiService.init();
// UserService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  if (
    !(to.name.indexOf("training-pack") >= 0) &&
    from.name &&
    !(from.name.indexOf("training-pack") >= 0)
  ) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
});

Vue.prototype.$API_URL = settings.API_URL;
Vue.prototype.$APP_URL = settings.APP_URL;

Vue.component("yandex-share", YandexShare);

Vue.use(Notifications);
Vue.use(VueYoutube);
Vue.use(VModal);
Vue.use(VueLazyload);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
