const APP_VERSION = "v 0.0.1";

// begin: PROD
let API_URL_V2 = "https://test.nwh.ru/api/web/v2/";
let API_URL = "https://test.nwh.ru/api/v1/";
let APP_URL = "https://test.nwh.ru/";
// end: PROD
// begin: STAGING
//let API_URL_V2 = "https://fs111.fitstars.ru/api/web/v2/";
//let API_URL = "https://fs111.fitstars.ru/api/v1/";
//let APP_URL = "https://fs111.fitstars.ru/";
// end: STAGING
// begin: LOCAL
//let API_URL_V2 = "http://192.168.31.174:8000/api/web/v2/";
//let API_URL = "http://192.168.31.174:8000/api/v1/";
//let APP_URL = "http://192.168.31.174:8000/";
// end: LOCAL

const settings = {
  APP_VERSION,
  API_URL_V2: `${API_URL_V2}`,
  API_URL: `${API_URL}`,
  APP_URL: `${APP_URL}`,
  ID_TOKEN: "token",
  REFRESH_TOKEN: "refresh_token",
};

export default settings;
