<template>
  <!-- BGGIN: VideoJS Player -->
  <div>
  <div v-if="training.video_id && isDefaultYoutube === false" id="videojs_container">
    <video
      class="video-js vjs-fluid vjs-16-9 vjs-custom"
      controls
      preload="auto"
      width="600"
      height="400"
      :id="training.id"
    ></video>
  </div>
  <!-- END: VideoJS Player -->

  <!-- BGGIN: YouTube Player -->
  <div v-else class="embed-responsive embed-responsive-16by9">
    <div id="training-video-by-id" class="yf-border-radius"></div>
  </div>
  </div>
  <!-- END: YouTube Player -->
</template>

<script>
import $ from "jquery";
import videojs from "video.js";
import CustomPlayCounter from '@/core/videojs/CustomPlayCounter';
import CustomPlayToggle from '@/core/videojs/CustomPlayToggle';
import CustomPlayListPrev from '@/core/videojs/CustomPlayListPrev';
import CustomPlayListNext from '@/core/videojs/CustomPlayListNext';
import CustomOverlay from '@/core/videojs/CustomOverlay';
import CustomProgress from '@/core/videojs/CustomProgress';
import CustomTitles from '@/core/videojs/CustomTitles';
import CustomTime from '@/core/videojs/CustomTime';
import CustomCountDown from '@/core/videojs/CustomCountDown';
// eslint-disable-next-line no-unused-vars
require('@silvermine/videojs-chromecast')(videojs);
import "videojs-hls-quality-selector";
import "videojs-contrib-quality-levels";
import "videojs-hotkeys";

import YouTubePlayer from "youtube-player";

import "video.js/dist/video-js.css";
import '@/core/videojs/theme/vjs-custom.css';
import "@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";

import { getYoutubeId } from "@/assets/js/helpers";

// Events constants
const EVENT_PLAY = 1;
const EVENT_PAUSE = 2;
const EVENT_STOP = 0;

// Events in DB constants
const EVENT_TO_DB_PLAY = 1;
const EVENT_TO_DB_PAUSE = 2;
const EVENT_TO_DB_STOP = 3;

// Player settings
const SETTINGS_EMIT_SIGNAL_VIDEO_READY = 1;
const SETTINGS_EMIT_SIGNAL_VIDEO_READY_PERCENT = 50;
const SETTINGS_PLAYER_VIDEOJS = 1;
const SETTINGS_PLAYER_YOUTUBE = 2;

export default {
  props: {
    training: Object,
    trainings: Array,
    program: Object,
    trainer: Object
  },
  name: "VideoPlayer",
  data() {
    return {
      timer: null,
      session: null,
      player: [],
      playerType: null
    };
  },
  mounted() {
    this.$root.$on("reloadVideoPlayer", () => {
      //window.location.reload();

      setTimeout(() => {
        this.init(true);
        this.$notify({
          type: "success",
          title: "Настройки плеера по умолчанию изменены"
        });
      }, 500);
    });

    this.init(true);
  },
  beforeDestroy() {
    this.$root.$off("reloadVideoPlayer");

    this._clearTimer();
    this._destroy();
  },
  computed: {
    isDefaultYoutube() {
      return this.$store.getters.isDefaultYoutube;
    },
    autoComplite() {
      return this.$store.getters.autoComplite;
    }
  },
  methods: {
    ///////////////////////////////////////////////////////////////////////////
    // Functions
    ///////////////////////////////////////////////////////////////////////////
    event(eventType, eventElement = null) {
      let player, durationFull, durationCurrent, percent;

      if (eventType === null) {
        return;
      }

      player = this._getPlayer();
      switch (this.playerType) {
        case SETTINGS_PLAYER_VIDEOJS:
          durationFull = player.duration();
          durationCurrent = player.currentTime();

          break;
        case SETTINGS_PLAYER_YOUTUBE:
          durationFull = eventElement.target.getDuration();
          durationCurrent = eventElement.target.getCurrentTime();

          break;
        default:
          return;
      }

      percent = (durationCurrent / durationFull) * 100;

      switch (eventType) {
        case EVENT_STOP:
          this._sendEvents(eventType);
          this._logEvents(eventType, durationCurrent, durationFull, percent);
          this._setTrainingIsReady(percent);
          this._clearTimer();

          break;
        case EVENT_PLAY:
          if (this.autoComplite == true) {
            this._setTimer(percent);
          }
          this._sendEvents(eventType);
          this._logEvents(eventType, durationCurrent, durationFull, percent);

          break;
        case EVENT_PAUSE:
          this._logEvents(eventType, durationCurrent, durationFull, percent);
          this._setTrainingIsReady(percent);
          this._clearTimer();

          break;
        default:
          break;
      }
    },
    eventYoutube(event) {
      if ((!event.data || event.data === null) && event.data !== EVENT_STOP) {
        return;
      }

      switch (event.data) {
        case 0:
        case 1:
        case 2:
          this.event(event.data, event);
          break;
        default:
          break;
      }
    },
    getYoutubeId,
    init(isNeedReloadPlugin = false) {
      this._clearSession();
      this._setPlayerType();

      switch (this.playerType) {
        case SETTINGS_PLAYER_VIDEOJS:
          this._initVideoJs(isNeedReloadPlugin);
          break;
        case SETTINGS_PLAYER_YOUTUBE:
        default:
          this._initYoutube();
          break;
      }

      this._clearTimer();
    },

    ///////////////////////////////////////////////////////////////////////////
    // Setters
    ///////////////////////////////////////////////////////////////////////////
    _setPlayerType(playerType = null) {
      if (
        playerType === null &&
        this.training &&
        this.training.video_id &&
        this.isDefaultYoutube === false
      ) {
        this.playerType = SETTINGS_PLAYER_VIDEOJS;
      } else if (playerType === null || this.isDefaultYoutube === true) {
        this.playerType = SETTINGS_PLAYER_YOUTUBE;
      } else {
        this.playerType = playerType;
      }

      switch (this.playerType) {
        case SETTINGS_PLAYER_VIDEOJS:
          if (this.player[SETTINGS_PLAYER_YOUTUBE]) {
            this.player[SETTINGS_PLAYER_YOUTUBE].removeEventListener(
              "stateChange"
            );
            this.player[SETTINGS_PLAYER_YOUTUBE].destroy();
          }

          break;
        case SETTINGS_PLAYER_YOUTUBE:
          if (this.player[SETTINGS_PLAYER_VIDEOJS]) {
            this.player[SETTINGS_PLAYER_VIDEOJS].off("play");
            this.player[SETTINGS_PLAYER_VIDEOJS].off("ended");
            this.player[SETTINGS_PLAYER_VIDEOJS].off("pause");
            this.player[SETTINGS_PLAYER_VIDEOJS].hide();
          }

          break;
      }
    },
    _setSession() {
      if (!this.session) {
        this.session = `${$.now()}-${this.training.current_user_id}-${
          this.training.id
        }`;
      }
    },
    _setTimer() {
      this.timer = setInterval(() => {
        let player, durationFull, durationCurrent;

        player = this._getPlayer();
        switch (this.playerType) {
          case SETTINGS_PLAYER_VIDEOJS:
            durationFull = player.duration();
            durationCurrent = player.currentTime();

            this._checkPercent(durationCurrent, durationFull);

            break;
          case SETTINGS_PLAYER_YOUTUBE:
            player
              .getDuration()
              .then(value => {
                durationFull = value;

                return player.getCurrentTime();
              })
              .then(value => {
                durationCurrent = value;

                this._checkPercent(durationCurrent, durationFull);
              });

            break;
          default:
            break;
        }
      }, 10000);
    },
    _setTrainingIsReady(percent) {
      if (
        percent > SETTINGS_EMIT_SIGNAL_VIDEO_READY_PERCENT &&
        !this.training.date_last_done &&
        this.autoComplite == true
      ) {
        this._emitSignals(SETTINGS_EMIT_SIGNAL_VIDEO_READY);
        this._clearTimer();
      }
    },
    _clearSession() {
      this.session = null;
    },

    ///////////////////////////////////////////////////////////////////////////
    // Getters
    ///////////////////////////////////////////////////////////////////////////
    _getEventToDB(eventType) {
      switch (eventType) {
        case EVENT_PLAY:
          return EVENT_TO_DB_PLAY;
        case EVENT_STOP:
          return EVENT_TO_DB_STOP;
        case EVENT_PAUSE:
          return EVENT_TO_DB_PAUSE;
        default:
          return null;
      }
    },
    _getPlayer() {
      let type;

      type = this.playerType;

      if (!this.player[type]) {
        this.player[type] = null;
      }

      return this.player[type];
    },

    ///////////////////////////////////////////////////////////////////////////
    // Private Functions
    ///////////////////////////////////////////////////////////////////////////
    _checkPercent(durationCurrent, durationFull) {
      let percent;

      percent = (durationCurrent / durationFull) * 100;

      if (this.training.date_last_done) {
        return;
      }

      if (percent > SETTINGS_EMIT_SIGNAL_VIDEO_READY_PERCENT) {
        this._sendEvents(EVENT_STOP);
      }

      this._setTrainingIsReady(percent);
    },
    _clearTimer() {
      clearInterval(this.timer);
    },
    _destroy(playerType) {
      let player, type;

      type = !playerType ? this.playerType : playerType;

      if (!this.player[type]) {
        this.player[type] = null;
      }
      player = this.player[type];

      if (!player) {
        return;
      }

      switch (type) {
        case SETTINGS_PLAYER_VIDEOJS:
          player.off("play");
          player.off("ended");
          player.off("pause");
          player.dispose();

          break;
        case SETTINGS_PLAYER_YOUTUBE:
          $(document).off();
          player.destroy();
          break;
        default:
          break;
      }

      player = null;
    },
    _emitSignals(type) {
      switch (type) {
        case SETTINGS_EMIT_SIGNAL_VIDEO_READY:
          this.$root.$emit("videoplayer.is.ready", true);

          break;
        default:
          break;
      }
    },
    beforeDestroy() { 
        this.player[this.playerType].dispose(); 
    },
    _initVideoJs(isNeedReloadPlugin) {
      let __self__ = this;

      setTimeout(() => {
        if (!__self__.training.video_id) {
          return;
        }
        if (document.getElementById('videojs_container')) {
          document.getElementById('videojs_container').innerHTML = `<video class="video-js vjs-fluid vjs-16-9 vjs-custom" controls preload="auto" width="600" height="400" id="${this.training.id}"></video>`;
        } 
        __self__.player[__self__.playerType] = videojs(
          document.getElementById(this.training.id),
          {
            fill: true,
            responsive: true,
            fluid: true,
            controls: true,
            autoplay: false,
            preload: "auto",
            handleHotkeys: true,
            techOrder: ["chromecast", "html5"],
            // techOrder: ["html5"],
            html5: {
              vhs: {
                overrideNative: true
              },
              nativeAudioTracks: false,
              nativeVideoTracks: false
            },
            hls: {
              overrideNative: true
            },
            plugins: {
              chromecast: {
                addButtonToControlBar: true
              },
              hotkeys: {
                volumeStep: 0.1,
                seekStep: 5,
                fullscreenKey: event => {
                  return (
                    event.which === 70 || (event.ctrlKey && event.which === 13)
                  );
                }
              }
            }
          }
        );
        __self__.player[__self__.playerType].chromecast();
        __self__.player[__self__.playerType].poster(__self__.training.poster);
        __self__.player[__self__.playerType].posterImage.off(['click', 'tap']);
        __self__.player[__self__.playerType].src({
          src: `https://static.fitstars.ru/videos/${__self__.training.video_id}/master.m3u8`,
          type: "application/x-mpegURL"
        });

        __self__.player[__self__.playerType].on("play", () => {
          __self__.event(EVENT_PLAY);
        });
        __self__.player[__self__.playerType].on("volumechange", () => {
          localStorage.setItem('volumeLevel', __self__.player[__self__.playerType].volume());
        });
        if (localStorage.getItem('volumeLevel')) {
          __self__.player[__self__.playerType].volume(localStorage.getItem('volumeLevel'))
        }
        __self__.player[__self__.playerType].on("ended", () => {``
          __self__.event(EVENT_STOP);
        });
        __self__.player[__self__.playerType].on("pause", () => {
          __self__.event(EVENT_PAUSE);
        });
        __self__.player[__self__.playerType].on("chromecastDevicesAvailable", () => {
          __self__.player[__self__.playerType].addClass('vjs-custom-with-chromecast');
        });

        setInterval(() => {
          if (__self__.player[SETTINGS_PLAYER_VIDEOJS]) {
            if (__self__.player[SETTINGS_PLAYER_VIDEOJS].controlBar.getChild('ChromecastButton') && __self__.player[SETTINGS_PLAYER_VIDEOJS].controlBar.getChild('ChromecastButton').el() && __self__.player[SETTINGS_PLAYER_VIDEOJS].controlBar.getChild('ChromecastButton').el().offsetWidth > 0) {
              __self__.player[SETTINGS_PLAYER_VIDEOJS].addClass('vjs-custom-with-chromecast');
            } else {
              __self__.player[SETTINGS_PLAYER_VIDEOJS].removeClass('vjs-custom-with-chromecast');
            }
          }
        }, 1000)

        if (isNeedReloadPlugin === true) {
          __self__.player[__self__.playerType].hlsQualitySelector({
            displayCurrentQuality: true
          });
        }
        videojs.registerComponent('CustomPlayCounter', CustomPlayCounter);
        videojs.registerComponent('CustomPlayToggle', CustomPlayToggle);
        videojs.registerComponent('CustomPlayListPrev', CustomPlayListPrev);
        videojs.registerComponent('CustomPlayListNext', CustomPlayListNext);
        videojs.registerComponent('CustomOverlay', CustomOverlay);
        videojs.registerComponent('CustomProgress', CustomProgress);
        videojs.registerComponent('CustomTitles', CustomTitles);
        videojs.registerComponent('CustomTime', CustomTime);
        videojs.registerPlugin('CustomCountDown', CustomCountDown);
        __self__.player[__self__.playerType].addChild('CustomPlayCounter', {showCounterBeforeStart: this.training.timer.countdown});
        __self__.player[__self__.playerType].addChild('CustomPlayToggle', {firstRun: true});
        __self__.player[__self__.playerType].addChild('CustomPlayListPrev', {training: this.training, trainings: this.trainings, program: this.program, prev: this.getPrevTraining(this.training.id)});
        __self__.player[__self__.playerType].addChild('CustomPlayListNext', {training: this.training, trainings: this.trainings, program: this.program, next: this.getNextTraining(this.training.id)});
        __self__.player[__self__.playerType].addChild('CustomOverlay');
        __self__.player[__self__.playerType].addChild('CustomProgress', {circles: this.training.circles, time_codes: this.training.time_codes});
        __self__.player[__self__.playerType].addChild('CustomTime', {start: this.training.timer.training_start, end: this.training.timer.training_end});
        __self__.player[__self__.playerType].addChild('CustomTitles', {titles: { title1: __self__.training.name, title2: __self__.program.name } });
        let times = this.training.timecodes.filter(t => t.countdown == 1).map(t => t.time_start-2);
        __self__.player[__self__.playerType].CustomCountDown({times: times });
      }, 100);
    },
    _initYoutube() {
      let __self__ = this;

      this._destroy(this.playerType);

      setTimeout(() => {
        __self__.player[__self__.playerType] = new YouTubePlayer(
          "training-video-by-id",
          {
            https: "https://www.youtube.com",
            width: 640,
            height: 360,
            videoId: __self__.getYoutubeId(__self__.training.video),
            playerVars: {
              rel: 0,
              showinfo: 0,
              enablejsapi: 1,
              autoplay: 0
            }
          }
        );

        __self__.player[__self__.playerType].on(
          "stateChange",
          __self__.eventYoutube
        );

        __self__.$root.$emit("reloadSocialTapeList", true);
      }, 100);
    },
    _logEvents(event, playerCurrentTime, playerDurationTime, percent) {
      let videoId, videoUrl;

      this._setSession();

      switch (this.playerType) {
        case SETTINGS_PLAYER_VIDEOJS:
          videoId = this.training.video_id;
          videoUrl = `https://static.fitstars.ru/videos/${this.training.video_id}/master.m3u8`;

          break;
        case SETTINGS_PLAYER_YOUTUBE:
          videoId = this.getYoutubeId(this.training.video);
          videoUrl = this.training.video;

          break;
        default:
          return;
      }

      this.$store.dispatch("logTrainingYoutubeData", {
        pack_id: this.program.id,
        video_id: videoId,
        video_url: videoUrl,
        video_type: this.playerType,
        training_id: this.training.id,
        duration: playerDurationTime,
        duration_stop: playerCurrentTime,
        duration_percent: percent,
        event_type: this._getEventToDB(event),
        view_id: this.session
      });
    },
    _sendEvents(eventType) {
      switch (eventType) {
        case EVENT_PLAY:
          window.carrotquest.track("Запустил видео тренировку", {
            "Название тренировки": this.training.name,
            "Название программы": this.program.name,
            Тренер: this.trainer.full_name
          });

          break;
        case EVENT_STOP:
          window.carrotquest.track("Просмотрел видео тренировку до конца", {
            "Название тренировки": this.training.name,
            "Название программы": this.program.name,
            Тренер: this.trainer.full_name
          });

          break;
        case EVENT_PAUSE:
        default:
          break;
      }
    },
    getPrevTraining(id) {
      let index = this.trainings.findIndex(tr => tr.id == id);
      if (index !== 0) {
        return this.trainings[index-1].id;
      } else {
        return 0;
      }
    },
    getNextTraining(id) {
      let index = this.trainings.findIndex(tr => tr.id == id);
      if (index !== this.trainings.length-1) {
        return this.trainings[index+1].id;
      } else {
        return 0;
      }
    },
  },
  watch: {
    training(toTraining, fromTraining) {
      if (
        toTraining.id !== fromTraining.id &&
        toTraining.video_id &&
        !fromTraining.video_id
      ) {
        this.init(true);
      } else if (toTraining.id !== fromTraining.id) {
        if (this.player[SETTINGS_PLAYER_VIDEOJS]) {
          document.getElementById('videojs_container').innerHTML = `<video class="video-js vjs-fluid vjs-16-9 vjs-custom" controls preload="auto" width="600" height="400" id="${toTraining.id}"></video>`;
        }
          this.init();
      }
    }
  }
};
</script>

<style>
.video-js .vjs-chromecast-button {
  margin-top: 1px; /*add margin top*/
  font-size: 0.7em;
}
</style>
