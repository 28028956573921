<template>
  <div id="top">
    <NoticeSubscriptionRenewal v-if="!subscriptions"/>

    <div class="page-head flex-wrap">
      <div class="page-title">
        <h2 class="orders-bold margin-bottom-20px">Моя подписка</h2>
      </div>

      <div v-if="!subscriptions" class="">
        <div class="subscribe-alert">Не активна</div>
      </div>
      <div v-else class="">
        <div class="subscribe-alert-green">
          {{
            getStringFromDate(
              subscriptions.ending_at,
              "DD.MM.YYYY",
              "Активна до "
            )
          }}
        </div>
      </div>
    </div>
    <div id="training_item">
      <div v-if="subscriptions" class="yf-bg-white active-subscription" style="">
        <a
          v-if="getStringFromDate(subscriptions.ending_at) !== 'Безлимит'"
          :href="`${$APP_URL}${siteSettings.checkout_redirect_from_cabinet}`"
          class="btn-buy show-mobile"
          style=""
        >
          Продлить
        </a>
        <table class="table table-no-borders current-subscription margin-left-50px">
          <thead>
            <tr>
              <div class="th th1">№ заказа</div>
              <div class="th th2">Активная подписка</div>
              <div class="th th3">Статус</div>
              <div class="th th4">Дата окончания</div>
              <div class="th th5">Автопродление</div>
              <div class="th th6"></div>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div class="td td1">{{ subscriptions.order_id }}</div>
              <div class="td td2 orders-bold">
                <p
                  v-if="subscriptions.order && subscriptions.order.gift == 1"
                  class="orders-gift"
                >
                  сертификат
                </p>
                <p class="w-100">{{ subscriptions.name }}</p>
              </div>
              <div
                v-if="subscriptions.expire_in_days <= 7"
                class="td td3 orders-bold"
              >
                <p class="status status-pain ends">Заканчивается</p>
              </div>
              <div v-else class="td td3 orders-bold">
                <p class="status status-pain payed">Активна</p>
              </div>
              <div class="td td4 orders-bold">
                {{ getStringFromDate(subscriptions.ending_at, "DD.MM.YYYY") }}
              </div>
              <div class="td td5 orders-bold" v-if="cards">Включено</div>
              <div class="td td5 orders-bold" v-else>Выключено</div>
              <div class="td td6">
                <a
                  v-if="getStringFromDate(subscriptions.ending_at) !== 'Безлимит'"
                  :href="
                    `${$APP_URL}${siteSettings.checkout_redirect_from_cabinet}`
                  "
                  class="btn-buy"
                  style=""
                >
                  Продлить
                </a>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-wrap" v-if="subscriptions && subscriptions.order && subscriptions.order.split">
        <h4 class="order-title orders-bold">Способы оплаты</h4>
        <div class="order-split margin-top-bottom-20">
          <a href="https://my.cloudpayments.ru" target="_blank">
            Изменить/Отменить
          </a>
        </div>
      </div>
      <div class="card-wrap" v-else-if="cards">
        <h4 class="order-title orders-bold">Способы оплаты</h4>
        <div
          class="card-block"
          v-for="card of cards"
          :key="`card-id-${card.id}`"
        >
          <div
            class="card-block__col card-block__card-icon"
            :class="card.card_type"
          ></div>
          <div class="card-block__col card-block__number">
            {{ card.mask }}
          </div>
          <div class="card-block__col card-block__txt">Дата привязки:</div>
          <div class="card-block__col card-block__add-data">
            {{ card.created_at }}
          </div>
          <span class="card-block__remove" @click="unfollow(card)">
            Удалить
          </span>
        </div>
      </div>
      <p class="margin-top-bottom-20" v-else>Нет привязанных карт</p>
      <h4 class="order-title orders-bold">История заказов</h4>
      <div class="portlet-body margin-bottom-20">
        <table
          v-if="orders"
          class="table table-no-borders orders-subscription margin-left-50px hide-table-mobile"
        >
          <thead>
            <tr>
              <div class="th th1">№ заказа</div>
              <div class="th th1">Подписка</div>
              <div class="th th2">Статус</div>
              <div class="th th3">Дата списания</div>
              <div class="th th4">Промокод</div>
              <div class="th th5">Сумма</div>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order of orders" :key="`order-id-${order.id}`">
              <div v-if="order.status == 'payed'" class="td td1">
                {{ order.id }}
              </div>
              <div v-else class="td td1">
                <a
                  :href="
                    `${$APP_URL}checkout?order_id=${order.id}&secret=${order.secret}`
                  "
                >
                  {{ order.id }}
                </a>
              </div>
              <div class="td td1">
                <p v-if="order.gift == 1" class="orders-gift">
                  сертификат
                </p>
                <p class="w-100">
                  {{ order.title }}
                </p>
              </div>
              <div class="td td2" v-if="order.status == 'payed'">
                <p :class="order.status" class="status status-pain">Оплачено</p>
              </div>
              <div class="td td2" v-else-if="order.status == 'cancelled'">
                <p :class="order.status" class="status status-pain">Возврат</p>
              </div>
              <div class="td td2" v-else>
                <p :class="order.status" class="status status-pain">
                  Ждет оплаты
                </p>
              </div>
              <div class="td td3">
                {{ getFormattedDate(order.created_at, "DD.MM.YYYY") }} г.
              </div>
              <div
                v-if="order.promocode && order.promocode.code"
                class="td td4"
              >
                {{ order.promocode.code }}
              </div>
              <div v-else class="td td4"></div>
              <div class="td td5">{{ order.sum.split(".")[0] }} руб.</div>
            </tr>
          </tbody>
        </table>
        <p v-else>Нет заказов</p>
        <table
          v-for="order of orders"
          :key="`no-order-id-${order.id}`"
          class="table table-no-borders yf-container-1200 orders-subscription margin-left-50px show-table-mobile"
        >
          <thead>
            <tr>
              <div class="th th1">№ заказа</div>
              <div class="th th1">Подписка</div>
              <div class="th th2">Статус</div>
              <div class="th th3">Дата списания</div>
              <div class="th th4">Промокод</div>
              <div class="th th5">Сумма</div>
            </tr>
          </thead>
          <tbody>
            <tr>
              <div v-if="order.status == 'payed'" class="td td1">
                {{ order.id }}
              </div>
              <div v-else class="td td1">
                <a :href="`${$APP_URL}checkout?order_id=${order.id}`">
                  {{ order.id }}
                </a>
              </div>
              <div class="td td1">
                <p v-if="order.gift == 1" class="orders-gift">
                  сертификат
                </p>
                <p class="w-100">
                  {{ order.title }}
                </p>
              </div>
              <div class="td td2">
                <p :class="order.status" class="status status-pain">
                  {{ order.status }}
                </p>
              </div>
              <div class="td td3">
                {{ getFormattedDate(order.created_at, "DD.MM.YYYY") }} г.
              </div>
              <div
                v-if="order.promocode && order.promocode.code"
                class="td td4"
              >
                {{ order.promocode.code }}
              </div>
              <div v-else class="td td4"></div>
              <div class="td td5">{{ order.sum.split(".")[0] }} руб.</div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Begin: MODAL -->
    <div
      aria-hidden="true"
      class="modal fade"
      id="remove-card-confirm"
      tabindex="-1"
    >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-hidden="true"
              class="close"
              data-dismiss="modal"
              type="button"
            ></button>
            <h4 class="modal-title">
              <strong>Удалить карту?</strong>
            </h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn dark btn-outline"
              data-dismiss="modal"
            >
              Отмена
            </button>
            <button
              type="button"
              class="btn red"
              data-dismiss="modal"
              @click="removeCard(removableCard)"
            >
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End: MODAL -->
  </div>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import "moment/locale/ru";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getFormattedDate } from "@/assets/js/helpers";

import Swal from "sweetalert2";

import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

export default {
  name: "OrdersPage",
  components: {
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      removableCard: null,
      reasons: []
    };
  },
  computed: {
    orders() {
      return this.$store.getters.userOrders;
    },
    subscriptions() {
      return this.$store.getters.userSubscriptions;
    },
    listReasons() {
      return this.$store.getters.userReasons;
    },
    cards() {
      return this.$store.getters.userCards;
    },
    isLoading() {
      return this.$store.getters.userLoaders;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    }
  },
  methods: {
    createHtmlListReasons() {
      let listReasons = '';

      this.listReasons.forEach(el => {
        const input =
        `
          <label class="checkbox" for="remove-card-${el.id}">
            <input type="checkbox" id="remove-card-${el.id}" name="option2" value="${el.id}">
            <span class="mr-3"></span>${el.reason}
          </label><Br>
        `

        listReasons = listReasons + input;
      })

      return listReasons;
    },
    htmlReasons() {
      const htmlReasons =
      `
        <p class="bold mb-6">Почему вы решили отключить автопродление?</p>
        <div class="ordes-step-2-wr text-left ml-10">
          ${this.createHtmlListReasons()}
        </div>
      `

      return htmlReasons;
    },
    getStringFromDate(dateString, format, text) {
      let resultText, dateEnd, date;

      if (!format) {
        format = "DD.MM.YYYY";
      }

      dateEnd = moment().add(2, "years");
      date = moment(dateString);

      if (dateEnd < date) {
        resultText = "Безлимит";
      } else if (text) {
        resultText = text + moment(date).format(format);
      } else {
        resultText = moment(date).format(format);
      }

      return resultText;
    },
    getOrders() {
      this.$store.dispatch("getUserOrders");
    },
    getSubscriptions() {
      this.$store.dispatch("getUserSubscriptions");
    },
    getCards() {
      this.$store.dispatch("getUserCards");
    },
    // unfollow(card){
    _step1(card) {
      Swal.fire({
        title: "Вы уверены?",
        text:
          "Отменив подписку вы больше не сможете тренироваться со своей персональной скидкой –70%",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Сохранить подписку",
        confirmButtonAriaLabel: "йцуйуйу",
        cancelButtonText: "Да, отменить",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        },
        confirmButtonColor: "#ef16b0"
      }).then(result => {
        if (result.value) {
          this._step2();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this._step3(card);
        }
      });
    },
    _step2() {
      Swal.fire({
        title: "Отлично!",
        text:
          "Рады, что вы сделали правильный выбор;) Продолжайте тренировки и добивайтесь новых результатов, со скидкой –70%. ",
        icon: "success",
        showCloseButton: true,
        confirmButtonText: "Выбрать программу!",
        confirmButtonColor: "#ef16b0"
      });
    },
    _step3(card) {
      Swal.fire({
        title: "Расскажите, что пошло не так?",
        html: this.htmlReasons(),
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Сохранить подписку",
        cancelButtonText: "Продолжить",
        confirmButtonColor: "#ef16b0",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        }
      }).then(result => {
        const ordesStep = document.querySelector('.ordes-step-2-wr');
        const inputs = ordesStep.querySelectorAll('input');

        this.reasons = [];

        inputs.forEach(input => {
          if (input.checked) {
            this.reasons.push(input.value);
          }
        })

        if (result.value) {
          Swal.fire({
            title: "Отлично!",
            text:
              "Рады, что вы сделали правильный выбор;) Продолжайте тренировки и добивайтесь новых результатов, со скидкой –70%. ",
            icon: "success",
            showCloseButton: true,
            confirmButtonText: "Выбрать программу!",
            confirmButtonColor: "#ef16b0"
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this._step4(card);
        }
      });
    },
    _step4(card) {
      let text = "К сожалению, вы потеряете все преимущества вашей подписки;(";

      if (this.subscriptions && this.subscriptions.ending_at) {
        text = `Внимание! После ${moment(this.subscriptions.ending_at).format(
          "DD.MM.YYYY"
        )} вы потеряете все преимущества вашей подписки ;(`;
      }

      Swal.fire({
        title: text,
        html:
          "<ul>" +
          "<li>Скидка –70%</li>" +
          "<li>Доступ ко всем программам</li>" +
          "<li>Доступ ко всем тренировкам</li>" +
          "<li>Программа питания</li>" +
          "<li>Активный образ жизни</li>" +
          "</ul>",
        icon: "error",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Сохранить подписку",
        cancelButtonText: "Отменить",
        confirmButtonColor: "#ef16b0",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        }
      }).then(result => {
        if (result.value) {
          Swal.fire({
            title: "Отлично!",
            text:
              "Рады, что вы сделали правильный выбор;) Продолжайте тренировки и добивайтесь новых результатов, со скидкой –70%. ",
            icon: "success",
            showCloseButton: true,
            confirmButtonText: "Выбрать программу!",
            confirmButtonColor: "#ef16b0"
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.removeCard(card);
        }
      });
    },
    unfollow(card) {
      this._step1(card);
    },
    removeCard(card) {
      this.$store.dispatch("sendReasonUnsubscribeList", { reasons: this.reasons }).then(() => {
        this.$store.dispatch("removeUserCard", card).then(() => {
          $("#remove-card-confirm").modal("hide");
          this.getSubscriptions();
          this.getCards();
        });
      });
    },
    removeCardConfirm(card) {
      this.removableCard = card;
      $("#remove-card-confirm").modal("show");
    },
    getFormattedDate
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Моя подписка" }]);
  },
  async created() {
    this.getOrders();
    this.getSubscriptions();
    await this.$store.dispatch("getUserCards");
    if (this.cards) {
      this.$store.dispatch('getReasonUnsubscribeList');
    }
  },
  setPageData() {
    switch (this.socialIsMyProfile) {
      case true:
        this.pageTitle = "Моя подписка - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "Моя подписка" }]);

        break;
      case false:
        this.pageTitle = "Моя подписка - FitStars.ru";
        this.$store.dispatch(SET_BREADCRUMB, [
          {
            title: "Моя подписка - FitStars.ru"
          }
        ]);

        break;
    }
  }
};
</script>

<style lang="scss">
.order-split {
  display: flex;
  align-items: flex-end;

  &::before {
    content: '';
    width: 83px;
    height: 24px;
    background-image: url(~@/assets/img/icons-svg/split.svg);
    margin-right: 10px;
  }
}
</style>
