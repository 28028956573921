import commentsService from "../../services/comments.service";

// action types
export const ADD_COMMENT = "addComment";
export const GET_COMMENTS_LIST = "getCommentsList";

// mutator types
export const COMMENT_IS_ADDING = "isCommentsListLoaded";
export const COMMENT_IS_ADDED = "isCommentsListLoaded";
export const COMMENTS_LIST_IS_LOADED = "isCommentsListLoaded";
export const COMMENTS_LIST_IS_LOADING = "isCommentsListLoading";

export default {
  state: {
    comment: {
      isAdded: false,
      isAdding: false
    },
    comments: {
      list: [],
      isLoaded: false,
      isLoading: false
    }
  },
  getters: {
    commentsList: state => state.comments.list,
    commentsIsLoading: state => state.comments.isLoading,
    commentsIsLoaded: state => state.comments.isLoaded
  },
  actions: {
    [GET_COMMENTS_LIST]({ commit }, request) {
      commit(COMMENTS_LIST_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        commentsService.getComments(
          request,
          response => {
            commit(GET_COMMENTS_LIST, response.data);

            commit(COMMENTS_LIST_IS_LOADED, true);
            commit(COMMENTS_LIST_IS_LOADING, false);

            resolve(response);
          },
          error => {
            commit(COMMENTS_LIST_IS_LOADED, false);
            commit(COMMENTS_LIST_IS_LOADING, false);

            reject(error);
          }
        );
      });
    },
    [ADD_COMMENT]({ commit }, request) {
      commit(COMMENT_IS_ADDED, false);
      commit(COMMENT_IS_ADDING, true);

      return new Promise((resolve, reject) => {
        commentsService.addComment(
          request,
          data => {
            commit(COMMENT_IS_ADDED, true);
            commit(COMMENT_IS_ADDING, false);

            resolve(data);
          },
          error => {
            commit(COMMENT_IS_ADDING, false);
            commit(COMMENT_IS_ADDED, false);

            reject(error);
          }
        );
      });
    },
  },
  mutations: {
    [GET_COMMENTS_LIST](state, comments) {
      state.comments.list = comments;
    },
    [COMMENTS_LIST_IS_LOADING](state, isLoading) {
      state.comments.isLoading = isLoading;
    },
    [COMMENTS_LIST_IS_LOADED](state, isLoaded) {
      state.comments.isLoaded = isLoaded;
    },
    [COMMENT_IS_ADDED](state, isAdded) {
      state.comment.isAdded = isAdded;
    },
    [COMMENT_IS_ADDING](state, isAdding) {
      state.comment.isAdding = isAdding;
    }
  }
};
