<template>
  <div class="programs">
    <div class="class">
      <NoticeSubscriptionRenewal v-if="!userSubscriptions"/>

      <!--begin::Блок заголовка с фильтром-->
      <div class="page-head page-head--nutrition">
        <div class="page-title">
          <h2>Рецепты</h2>
        </div>
        <div class="nutrition-header-block">
          <div class="nutrition-header-block-info">
            <h3 class="nutrition-text--header-block-info-title">
              Правильное питание
            </h3>
            <div class="nutrition-text--header-block-info-description">
              Узнай всего за 10 уроков, из каких продуктов строить свой рацион, чтобы быть здоровой и красивой.
            </div>
            <div class="nutrition-header-block-info-buttons">
              <span class="nutrition-button--info">10 видеоуроков</span>
              <span class="nutrition-button--info">Теория и практика</span>
              <span class="nutrition-button--info">Инна Кононенко</span>
            </div>
            <div class="nutrition-header-block-info-buttons">
              <router-link class="nutrition-button--detail" to="/training/149">
                Открыть курс
              </router-link>
            </div>
          </div>
          <div class="nutrition-header-block-preview">
            <iframe
              class="iframe"
              src="https://www.youtube.com/embed/p3Gp_jJOETk"
              allowfullscreen
              frameborder="0"
              height="265"
            ></iframe>
          </div>
        </div>

        <div class="nutrition-header-block-mobile">
          <router-link
            class="nutrition-header-block-mobile__link"
            to="/training/149"
          >
            <div class="nutrition-header-block-mobile__header">
              <span class="nutrition-mobile-info-name">Инна Кононенко</span>
              <span class="nutrition-mobile-info-rate">1560</span>
            </div>
            <div class="nutrition-header-block-mobile-preview">
              <img
                alt="О курсе"
                src="@/assets/img/thumb__270_0_0_0_crop.png"
                class="nutrition-img--preview"
              />
            </div>
            <div class="nutrition-header-block-mobile__content">
              <h4 class="nutrition-info-mobile-title" sty>
                Правильное питание
              </h4>
              <p class="nutrition-info-mobile-item">
                Цель: похудение, тонус
              </p>
              <p class="nutrition-info-mobile-item">
                Цикл: 10 дней
              </p>
              <div
                class="nutrition-info-mobile-item nutrition-info-mobile-video"
              >
                10 видеоуроков
              </div>
            </div>
          </router-link>
        </div>
        <!--
        <div class="filter-test" style="width:100%">
          <div>
            <div class="filter-test__content">
              <div class="filter-test__btn filter-test__wrap">
                1000 Ккал
              </div>
              <div class="col-divider"></div>
              <div class="filter-test__btn filter-test__wrap">
                1200 Ккал
              </div>
              <div class="col-divider"></div>
              <div class="filter-test__btn filter-test__wrap">
                1500 Ккал
              </div>
              <div class="col-divider"></div>
              <div class="filter-test__btn filter-test__wrap">
                2000 Ккал
              </div>
            </div>
            <div class="filter-test__content">
              <div class="filter-test__checkbox filter-test__wrap">
                <div class="custom-checkbox-wr">
                  <input id="1x" class="custom-checkbox" type="checkbox">
                  <label class="filter-test__checkbox--label custom-label" for="1x"></label>
                  <p class="filter-test__checkbox--text">Без мяса</p>
                </div>
                <div class="custom-checkbox-wr">
                  <input id="2x" class="custom-checkbox" type="checkbox">
                  <label class="filter-test__checkbox--label custom-label" for="2x"></label>
                  <p class="filter-test__checkbox--text">Без масла</p>
                </div>
              </div>
              <div class="col-divider"></div>
              <div
                class="filter-test__checkbox filter-test__wrap"
                style="flex-grow: 1;"
              >
                <div class="custom-checkbox-wr">
                  <input id="1y" class="custom-checkbox" type="checkbox">
                  <label class="filter-test__checkbox--label custom-label" for="1y"></label>
                  <p class="filter-test__checkbox--text">Завтрак</p>
                </div>
                <div class="custom-checkbox-wr">
                  <input id="2y" class="custom-checkbox" type="checkbox">
                  <label class="filter-test__checkbox--label custom-label" for="2y"></label>
                  <p class="filter-test__checkbox--text">Обед</p>
                </div>
                <div class="custom-checkbox-wr">
                  <input id="3y" class="custom-checkbox" type="checkbox">
                  <label class="filter-test__checkbox--label custom-label" for="3y"></label>
                  <p class="filter-test__checkbox--text">Ужин</p>
                </div>
                <div class="custom-checkbox-wr">
                  <input id="4y" class="custom-checkbox" type="checkbox">
                  <label class="filter-test__checkbox--label custom-label" for="4y"></label>
                  <p class="filter-test__checkbox--text">Десерт</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FSRecipeFilter
          :key="`program-item-key-component-filter`"
        ></FSRecipeFilter> -->
      </div>
      <!--end::Блок заголовка с фильтром-->

      <div class="page-body">
        <!--begin::Блок пустой список после фильтрации-->
        <div v-if="recipesByCategories.length < 1" class="col-xs-12 mt-11">
          По заданным вами критериям ничего не найдено, измени, пожалуйста,
          {{ " " }}
          настройки поиска
        </div>
        <!--end::Блок пустой список после фильтрации-->

        <div
          v-else
          v-for="(category, indexCategory) in recipesByCategories"
          :key="`programs-categories-${indexCategory}`"
          :class="{ 'mt-0': indexCategory === 0 }"
        >
          <h3
            :id="`anchor-${category.code}`"
            class="programs-categories__name"
            v-if="!recipesIsFiltered && category && category.code !== 'none'"
          >
            {{ category.name }}
          </h3>

          <!--begin::Блок списока рецептов-->
          <div class="catalog" v-if="category">
            <div
              v-if="recipesIsFiltered === false"
              :id="`frame-${indexCategory}-${category.code}`"
              class="frame"
            >
              <ul class="slidee">
                <li
                  v-for="(recipe, indexRecipe) of category.recipes"
                  :key="
                    `program-item-key-${indexCategory}-${indexRecipe}-${recipe.id}`
                  "
                  class="catalog--item recipes"
                  @click="goToRecipe(recipe)"
                >
                  <FSRecipeItem
                    :indexRecipe="indexRecipe"
                    :indexCategory="indexCategory"
                    :userSubscriptions="userSubscriptions"
                    :recipe="recipe"
                    :key="
                      `program-item-key-component-${indexCategory}-${indexRecipe}-${recipe.id}`
                    "
                  ></FSRecipeItem>
                </li>
              </ul>
            </div>
            <div v-else>
              <ul class="flex flex-wrap catalog--on-filter">
                <li
                  v-for="(recipe, indexRecipe) of category.recipes"
                  :key="
                    `program-item-key-${indexCategory}-${indexRecipe}-${recipe.id}`
                  "
                  class="catalog--item"
                  @click="goToRecipe(recipe)"
                >
                  <FSRecipeItem
                    :indexRecipe="indexRecipe"
                    :indexCategory="indexCategory"
                    :userSubscriptions="userSubscriptions"
                    :recipe="recipe"
                    :key="
                      `program-item-key-component-${indexCategory}-${indexRecipe}-${recipe.id}`
                    "
                  ></FSRecipeItem>
                </li>
              </ul>
            </div>
          </div>
          <!--end::Блок списока рецептов-->

          <!--begin::Блок якорей для перехода на категорию-->
          <div v-if="category.hasAnchors && category.hasAnchors === true">
            <h3 class="mb-8">По направлениям</h3>
            <div class="btn-ancher">
              <span
                v-for="(anchor, indexAnchor) of recipesByCategories"
                :key="`program-item-key-anchors-${indexAnchor}`"
                :class="`${anchor.code}`"
              >
                <span
                  v-if="anchor.isAnchorShow === true"
                  class="btn btn--filter"
                  @click="scrollToAchor(anchor.code)"
                >
                  {{ anchor.name }}
                </span>
              </span>
            </div>
          </div>
          <!--end::Блок якорей для перехода на категорию-->
        </div>
      </div>
    </div>

    <!--begin::Блок модального окна продления подписки-->
    <div class="remodal" data-remodal-id="buy-training">
      <div class="modalr">
        <div class="modalr-close" data-remodal-action="close"></div>
        <div class="modalr-content">
          <h3>
            Чтобы выбрать программу тренировок необходимо продлить подписку
          </h3>
          <a
            :href="`${$APP_URL}${siteSettings.checkout_redirect_from_cabinet}`"
            class="btn btn-success btn-large"
          >
            Продлить
          </a>
        </div>
      </div>
    </div>
    <!--end::Блок модального окна продления подписки-->
  </div>
</template>

<script>
import $ from "jquery";
import "remodal/dist/remodal.min";

// import FSRecipeFilter from "@/components/PagePartials/recipes/Filter.vue";
import FSRecipeItem from "@/components/PagePartials/recipes/Item.vue";
import NoticeSubscriptionRenewal from '@/components/content/NoticeSubscriptionRenewal.vue';

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_RECIPES_LIST } from "@/core/services/store/recipes.module";

import { getYoutubeId, getFormattedDate } from "@/assets/js/helpers";

window.jQuery = $;

export default {
  name: "PageRecipesOLD",
  components: {
    // FSRecipeFilter,
    FSRecipeItem,
    NoticeSubscriptionRenewal
  },
  data() {
    return {
      slyElements: [],
      modalBuyTraining: null,
      recipeUrl: null
    };
  },
  computed: {
    recipesByCategories() {
      return this.$store.getters.recipesByCategories;
    },
    recipesIsFiltered() {
      return this.$store.getters.recipesIsFiltered;
    },
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    },
    isLoading() {
      return this.$store.getters.recipesIsLoading;
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    }
  },
  beforeDestroy() {
    this.$root.$off("reloadRecipesPage");
  },
  mounted() {
    let __self__ = this;

    this._setRecipesPage();

    this.$root.$on("reloadRecipesPage", function() {
      __self__._setRecipesPage();
    });
  },
  methods: {
    getYoutubeId,
    getFormattedDate,
    getRecipes() {
      this.$store.dispatch(GET_RECIPES_LIST).then(() => {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "content-loading");
        this.$store.dispatch("getUserSubscriptions");

        this.setSlyForRecipes();
      });
    },
    setSlyForRecipes() {
      let __self__ = this;

      if (!this.recipesByCategories || this.recipesByCategories === []) {
        return;
      }

      setTimeout(() => {
        __self__.slyElements = [];

        __self__.recipesByCategories.forEach(function(category, index) {
          let elementSly = $(`#frame-${index}-${category.code}`);
          let options = {
            horizontal: true,
            itemNav: "basic",
            easing: "swing",
            dragHandle: true,
            speed: 300,
            scrollHijack: 500,
            scrollBy: 2,
            scrollTrap: true,
            mouseDragging: 1,
            touchDragging: 1
          };

          if (elementSly.length < 1) {
            return;
          }

          __self__.slyElements[category.code] = elementSly;

          elementSly.sly(options).init();
        });
      }, 200);
    },
    goToRecipe(recipe) {
      let available = false;

      if (this.userSubscriptions) {
        available = true;
      }

      if (available === false) {
        this._openBuyTraining(recipe.url);
      } else if (available === true) {
        this.$router.push(`/recipes/${recipe.id}`);
      }
    },
    scrollToAchor(anchorCode) {
      let heightElementSubheader = $("#kt_subheader").height();
      let heightElementHeader = $("#kt_header").height();
      let heightElementAnchorH3 = $(`#anchor-${anchorCode}`).height();

      $([document.documentElement, document.body]).animate(
        {
          scrollTop:
            $(`#anchor-${anchorCode}`).offset().top -
            heightElementSubheader -
            heightElementHeader -
            heightElementAnchorH3 -
            15
        },
        2000
      );
    },
    _openBuyTraining(url) {
      this.recipeUrl = url;

      this.$nextTick(() => {
        this.modalBuyTraining.open();
      });
    },
    _setRecipesPage() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "content-loading");
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Рецепты" }]);

      this.modalBuyTraining = $("[data-remodal-id='buy-training']").remodal({
        hashTracking: false
      });

      this.getRecipes();
    }
  },
  watch: {
    $route() {
      this._setRecipesPage();
    },
    // eslint-disable-next-line no-unused-vars
    recipesIsFiltered(to, from) {
      if (to === false) {
        this.setSlyForRecipes();
      } else if (to === true && this.slyElements.length > 0) {
        //  this.slyElements.forEach(sly => {
        //    sly.destroy();
        //  });

        this.slyElements = [];
      }
    }
  }
};
</script>

<style lang="scss">
.card-footer {
  .frame {
    overflow: hidden;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      zoom: 1;

      li {
        float: left;
        height: 100%;
        margin: 0 10px;
        width: 114px;
        min-width: 114px;
      }
    }

    .slidee {
      margin: 0 -10px;
      padding: 0;
      padding-top: 14px;
      height: 100%;
      list-style: none;
    }
  }

  .scrollbar {
    margin: 0 0 1em 0;
    height: 2px;
    background: #ccc;
    line-height: 0;

    .handle {
      width: 51px;
      height: 100%;
      background: #292a33;
      cursor: pointer;

      .mousearea {
        position: absolute;
        top: -5px;
        left: 0;
        width: 51px;
        height: 12px;
        background: #c4c4c4;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .page-head--nutrition {
    flex-direction: column;
  }
}
</style>
