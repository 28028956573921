<template>
  <ul class="menu-nav" v-if="counters">
    <!-- BEGIN: Мои программы -->
    <router-link
      to="/programs"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="{
          'menu-item-active': isActive && myProgramsPage === false,
          'menu-item-active': isExactActive && myProgramsPage === false
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-squares-3 show-on show-on--tablet"></i>
          <span class="menu-text">Программы ({{counters.number_of_programs}})</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои программы -->

    <!-- BEGIN: Тренировки -->
    <router-link
      to="/trainings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon flaticon-presentation show-on show-on--tablet"
          ></i>
          <span class="menu-text">Тренировки ({{counters.number_of_trainings}})</span>
        </a>
      </li>
    </router-link>
    <!-- END: Тренировки -->

    <!-- BEGIN: Мои курсы -->
    <router-link
      to="/courses"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="{
          'menu-item-active': isActive && myCoursesPage === false,
          'menu-item-active': isExactActive && myCoursesPage === false
        }"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon flaticon2-files-and-folders show-on show-on--tablet"
          ></i>
          <span class="menu-text">Курсы ({{counters.number_of_courses}})</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои курсы -->

    <!-- BEGIN: Питание -->
    <router-link
      to="/recipes"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-black show-on show-on--tablet"></i>
          <span class="menu-text">Рецепты ({{counters.number_of_recipes}})</span>
        </a>
      </li>
    </router-link>

    <!-- BEGIN: Разделитель -->
    <li class="menu-item show-on show-on--tablet" style="height: 18px">
      <span class="menu-link">
        <span class="menu-text border-top"></span>
      </span>
    </li>
    <!-- END: Разделитель -->

    <router-link
      :to="`/fitness-profile/${userData.url_slug}`"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-user"></i>
          <span class="menu-text">Мой профиль</span>
        </a>
      </li>
    </router-link>

    <!-- BEGIN: Мои программы -->
    <router-link
      to="/programs/my"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-menu-2"></i>
          <span class="menu-text">Мои программы</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои программы -->

    <!-- BEGIN: Мои курсы -->
    <router-link
      to="/courses/my"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-folder"></i>
          <span class="menu-text">Мои курсы</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои программы -->

    <!-- BEGIN: Мои тренировки -->
    <router-link
      to="/training"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-chronometer"></i>
          <span class="menu-text">Мои тренировки</span>
        </a>
      </li>
    </router-link>
    <!-- END: Мои тренировки -->

    <router-link
      to="/progress"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-cup"></i>
          <span class="menu-text">Мой прогресс</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/favorites"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-star"></i>
          <span class="menu-text">Избранные тренировки</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/orders"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon far fa-credit-card"></i> -->
          <i class="menu-icon flaticon-price-tag"></i>
          <span class="menu-text">Моя подписка</span>
        </a>
      </li>
    </router-link>

    <!-- BEGIN: Разделитель -->
    <li class="menu-item show-on show-on--tablet" style="height: 18px">
      <span class="menu-link">
        <span class="menu-text border-top"></span>
      </span>
    </li>
    <!-- END: Разделитель -->

    <!-- BEGIN: Подарочный сертификат  -->
    <li class="menu-item show-on show-on--tablet">
      <a
        target="_blank"
        :href="'https://fitstars.ru/make-a-gift'"
        class="menu-link"
        style="align-items: center"
      >
        <i class="menu-icon flaticon2-gift"></i>
        <span class="menu-text">Подарочный сертификат</span>
      </a>
    </li>
    <!-- END: Подарочный сертификат -->

    <!-- BEGIN: Пригласи друга -->
    <router-link
      to="/invite-friends"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-avatar"></i>
          <span class="menu-text">Пригласи друга</span>
        </a>
      </li>
    </router-link>
    <!-- END: Пригласи друга -->

    <!-- BEGIN: Разделитель -->
    <li class="menu-item show-on show-on--tablet" style="height: 18px">
      <span class="menu-link">
        <span class="menu-text border-top"></span>
      </span>
    </li>
    <!-- END: Разделитель -->

    <router-link
      to="/new-profile"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item show-on show-on--tablet"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon far fa-credit-card"></i> -->
          <i class="menu-icon flaticon2-settings"></i>
          <span class="menu-text">Настройки</span>
        </a>
      </li>
    </router-link>

    <li class="menu-item show-on show-on--tablet" @click="onLogout">
      <a class="menu-link">
        <i class="menu-icon flaticon2-left-arrow-1"></i>
        <span class="menu-text">Выход</span>
      </a>
    </li>
  </ul>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import { LOGOUT } from "@/core/services/store/auth.module";

import settings from "@/settings";

export default {
  name: "KTMenu",
  data() {
    return {
      subscriptionState: {},
      myProgramsPage: false,
      myCoursesPage: false
    };
  },
  computed: {
    ...mapGetters(["userSubscriptions", "counters"]),
    ...mapGetters(["userData", "currentUserPersonalInfo"]),
    siteSettings() {
      return this.$store.getters.siteSettings;
    }
  },
  methods: {
    onLogout() {
      let __self__ = this;

      Swal.fire({
        title: "Вы уверены?",
        text: "Что хотите выйти из личного кабинета",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Выйти",
        confirmButtonColor: "#ef16b0",
        cancelButtonText: "Отменить",
        customClass: {
          cancelButton: "btn btn-white btn-border"
        },
        reverseButtons: true
      }).then(function(result) {
        if (result.value) {
          __self__.$store.dispatch(LOGOUT).then(() => {
            window.location.href = settings.APP_URL;
          });
        }
      });
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    getDaysLabel(days, labels) {
      const cases = [2, 0, 1, 1, 1, 2];
      return labels[
        days % 100 > 4 && days % 100 < 20
          ? 2
          : cases[days % 10 < 5 ? days % 10 : 5]
      ];
    },
    setSubscriptionState() {
      const state = {};
      const isSubscriptionEnd = !this.userSubscriptions;

      if (isSubscriptionEnd) {
        state.type = "danger";
        state.text = "Срок действия твоей подписки истек";
        state.button = "prolong";
        state.buttonText = this.siteSettings.orders_button_title;
        state.buttonTextMobile = this.siteSettings.orders_button_title;

        this.subscriptionState = { ...state };
        return;
      }

      const daysRemaining = parseInt(this.userSubscriptions.expire_in_days, 10);
      const daysLabel = this.getDaysLabel(daysRemaining, [
        "день",
        "дня",
        "дней"
      ]);
      const leftLabel = this.getDaysLabel(daysRemaining, [
        "остался",
        "осталось",
        "осталось"
      ]);
      const isTrial = this.userSubscriptions.trial;

      if (isTrial) {
        state.type = "muted";
        state.text = `До окончания пробного периода ${leftLabel} ${daysRemaining} ${daysLabel}`;
        state.button = "prolong";
        state.buttonText = this.siteSettings.orders_button_title;
        state.buttonTextMobile = this.siteSettings.orders_button_title;

        this.subscriptionState = { ...state };
        return;
      }

      if (daysRemaining <= 7) {
        state.type = "danger";
        state.text = `До окончания подписки ${leftLabel} ${daysRemaining} ${daysLabel}`;
        state.button = "prolong";
        state.buttonText = this.siteSettings.orders_button_title;
        state.buttonTextMobile = this.siteSettings.orders_button_title;
      } else if (daysRemaining > 7) {
        state.button = "subscriptions";
      }

      this.subscriptionState = { ...state };
    },
    _setProgramsPage() {
      if (this.$route.name === "programs-my") {
        this.myProgramsPage = true;
      } else {
        this.myProgramsPage = false;
      }

      if (this.$route.name === "courses-my") {
        this.myCoursesPage = true;
      } else {
        this.myCoursesPage = false;
      }
    }
  },
  mounted() {
    this._setProgramsPage();

    this.$store.dispatch("getTrainingsCalendar");
    this.$store.dispatch("getTrainingsCompleted");
    this.$store.dispatch("getCounters");
    this.$store
      .dispatch("getUserSubscriptions")
      .then(() => {
        return this.$store.dispatch("getSettings");
      })
      .then(() => {
        this.setSubscriptionState();
      });
  },
  watch: {
    $route() {
      this._setProgramsPage();
    },
    userData() {
      if (this.userData.name) {
        this.userName = `${this.userData.name} (${this.userData.email})`;
        this.userNameFirstLatter = this.userData.name.charAt(0).toUpperCase();
      } else {
        this.userName = this.userData.email;
        this.userNameFirstLatter = this.userData.email.charAt(0).toUpperCase();
      }
    }
  }
};
</script>
