import axios from "axios";

const headers = {
  "Content-Type": "application/json"
};

const instance = axios.create({
  headers
});
const notifyAboutError = () => {
  // empty
};

instance.interceptors.response.use(
  response => response,
  error => {
    notifyAboutError(error);
    return Promise.reject(error);
  }
);

export default instance;
