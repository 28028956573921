<template>
  <!-- <div v-if="comments && coments.length > 0" class="training-comment-list-wr"> -->
  <div v-if="comments && comments.length > 0" class="training-comment-list-wr">
    <div class="comments-title">Все отзывы</div>
    <div
      class="fs-ptile training-comment-list__item"
      v-for="(comment, indexComment) of comments"
      :key="`comment-data-${indexComment}`"
    >
      <div
        v-if="comment && Object.keys(comment).length > 0"
        style="width: 100%"
      >
        <div class="flex">
          <router-link
            class="training-comment-list__item--avatar"
            :to="`/fitness-profile/${comment.user.url_slug}`"
          >
            <img
              v-if="comment.user.avatar"
              class="fs-ptile-img"
              :src="`${comment.user.avatar_image}`"
            />
            <span v-else class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ comment.user.full_name.charAt(0).toUpperCase() }}
              </span>
            </span>
          </router-link>
          <div class="training-comment-list__item--user-info">
            <div class="comment-user-name">{{ comment.user.full_name }}</div>
            <div class="comment-user-data">
              {{ getFormattedDate(comment.created_at, "DD.MM.YYYY") }}
            </div>
          </div>
        </div>
        <div
          class="training-comment-list__item--comment"
          v-html="comment.body"
        ></div>

        <div
          class="flex"
          v-for="(subComment, iSubComment) of comment.reply.slice()"
          :key="`sub-comment-data-${iSubComment}`"
        >
          <router-link
            class="training-comment-list__item--avatar"
            :to="`/fitness-profile/${subComment.user.url_slug}`"
          >
            <img
              v-if="subComment.user.avatar && subComment.user.avatar.path"
              class="fs-ptile-img"
              :src="`${subComment.user.avatar_image}`"
            />
            <span v-else class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">
                {{ subComment.user.full_name.charAt(0).toUpperCase() }}
              </span>
            </span>
          </router-link>
          <div class="training-comment-list__item--user-info">
            <div
              class="comment-user-name"
              style="display: flex; align-items: center"
            >
              {{ subComment.user.full_name }}
              <div class="comment-user-data" style="margin-left: 8px">
                {{ getFormattedDate(subComment.created_at, "DD.MM.YYYY") }}
              </div>
            </div>
            <div
              class="training-comment-list__item--comment"
              v-html="subComment.body"
            ></div>
          </div>
        </div>
        <div>
          <div class="training-comment-list__item--form">
            <div class="training-comment-list__item--form__line"></div>
            <textarea
              id="kt_autosize_1"
              class="training-comment-list__item--form__textarea"
              v-model="commentBody[comment.id]"
              rows="1"
              placeholder="Ответить..."
            ></textarea>
            <div
              class="training-comment-list__item--form__send"
              @click="sendComment(comment.id)"
            >
              <i class="menu-icon flaticon2-send-1"></i>
            </div>
            <!-- <div class="flex flex--center flex--wrap flex--sc-end">
              <button class="btn btn--succses" @click="sendComment(comment.id)">
                Отправить
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFormattedDate } from "@/assets/js/helpers";

import {
  ADD_COMMENT,
  GET_COMMENTS_LIST
} from "@/core/services/store/comments.module";

export default {
  name: "FSComments",
  props: {
    commentObject: Object,
    type: Number
  },
  data() {
    return {
      commentBody: [],
      currentPage: null
    };
  },
  computed: {
    comments() {
      return this.$store.getters.commentsList;
    }
  },
  beforeDestroy() {
    this.$root.$off("page_comments__send_comment");
  },
  mounted() {
    let __self__ = this;

    this.$root.$on("page_comments__send_comment", function() {
      __self__.getComments();
    });

    this.getComments();
  },
  methods: {
    getFormattedDate,
    getUserAvatar(avatar) {
      if (avatar.path != null) {
        return `${avatar.path}`;
      }

      return null;
    },
    getComments() {
      this.$store
        .dispatch(GET_COMMENTS_LIST, {
          page: this.currentPage,
          training_id: this.commentObject.id,
          type: this.type
        })
        .then(() => {
          this.commentBody = [];
        });
    },
    moreComments() {
      this.$store.dispatch(GET_COMMENTS_LIST, {
        page: this.currentPage + 1,
        trainingId: this.commentObject.id,
        type: this.type
      });
    },
    sendComment(parentCommentId) {
      this.$store
        .dispatch(ADD_COMMENT, {
          training_id: this.commentObject.id,
          parent: parentCommentId || null,
          content: this.commentBody[parentCommentId],
          type: this.type
        })
        .then(() => {
          this.commentBody[parentCommentId] = "";

          this.getComments();
        });
    }
  },
  watch: {
    commentObject() {
      this.getComments();
    }
  }
};
</script>
