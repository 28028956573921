var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":`/fitness-profile/${_vm.userData.url_slug}`},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мой профиль")])])])]}}])}),_c('router-link',{attrs:{"to":"/programs/my"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-menu-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мои программы")])])])]}}])}),_c('router-link',{attrs:{"to":"/courses/my"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мои курсы")])])])]}}])}),_c('router-link',{attrs:{"to":"/training"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-chronometer"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мои тренировки")])])])]}}])}),_c('router-link',{attrs:{"to":"/progress"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cup"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мой прогресс")])])])]}}])}),_c('router-link',{attrs:{"to":"/favorites"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-star"}),_c('span',{staticClass:"menu-text"},[_vm._v("Избранные тренировки")])])])]}}])}),_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-price-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v("Моя подписка")])])])]}}])}),_vm._m(0),_c('li',{staticClass:"menu-item"},[_c('a',{staticClass:"menu-link",staticStyle:{"align-items":"center"},attrs:{"target":"_blank","href":'https://fitstars.ru/certificate'}},[_c('i',{staticClass:"menu-icon flaticon2-gift"}),_c('span',{staticClass:"menu-text"},[_vm._v("Подарочный сертификат")])])]),_c('router-link',{attrs:{"to":"/invite-friends"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-avatar"}),_c('span',{staticClass:"menu-text"},[_vm._v("Пригласи друга")])])])]}}])}),_vm._m(1),_c('li',{staticClass:"menu-item",on:{"click":_vm.onLogout}},[_vm._m(2)]),_vm._m(3)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item--separator"},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text border-top"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item menu-item--separator"},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text border-top"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link"},[_c('i',{staticClass:"menu-icon flaticon2-left-arrow-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Выход")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aside-app desk-aside-app"},[_c('div',{staticClass:"aside-app__title"},[_vm._v(" Скачайте приложение: ")]),_c('div',{staticClass:"aside-app__btn-wr"},[_c('div',{staticClass:"aside-app__btn",attrs:{"onclick":"window.open('https://redirect.appmetrica.yandex.com/serve/99303992990976435', '_blank');"}},[_c('img',{attrs:{"src":require("@/assets/img/icons-svg/google-play.svg")}})]),_c('div',{staticClass:"aside-app__btn",attrs:{"onclick":"window.open('https://redirect.appmetrica.yandex.com/serve/171361584696874164', '_blank');"}},[_c('img',{attrs:{"src":require("@/assets/img/icons-svg/apple-play.svg")}})])])])
}]

export { render, staticRenderFns }