<template>
  <div
    v-if="socialIsMyProfile === true"
    class="fs-ptile profile__invite"
    id="jsid-profile-share-compotent"
  >
    <h5 class="fs-ptile-title">Как добавить друга?</h5>
    <div style="width: 100%">
      <p>
        <span class="list-type">1.</span>
        Скопируй ссылку с приглашением
      </p>
      <p>
        <span class="list-type">2.</span>
        Отправь ее своему другу или поделись в соц. сетях
      </p>
      <p>
        <span class="list-type">3.</span>
        Друг получит бесплатный доступ ко всем программам на 7 дней!
      </p>
    </div>
    <div style="margin-top: 25px">
      <button style="margin-bottom: 15px" @click="copyInvitationLink()">
        <img src="@/assets/img/profile/copy-btn.svg" alt="" />
      </button>
      <p>или поделись</p>
      <div class="yandex-share-links">
        <yandex-share
          :services="[
            'vkontakte',
            'facebook',
            'twitter',
            'telegram',
            'odnoklassniki',
            'viber',
            'whatsapp'
          ]"
          :url="getInvitationLink()"
          counter
        />
      </div>
    </div>
  </div>
</template>

<script>
import settings from "@/settings";
import defaultPhoto from "@/assets/img/noimage.png";
import YandexShare from "@cookieseater/vue-yandex-share";
import { copyToClipboard } from "@/assets/js/helpers";
import Swal from "sweetalert2";

export default {
  name: "FSProfileShare",
  props: {
    url_slug: String
  },
  data() {
    return {
      defaultSettings: {
        APP_URL: settings.APP_URL,
        defaultPhoto: defaultPhoto
      }
    };
  },
  components: {
    YandexShare
  },
  computed: {
    socialIsMyProfile() {
      return this.$store.getters.socialIsMyProfile;
    }
  },
  methods: {
    copyToClipboard: copyToClipboard,
    getInvitationLink() {
      const link = `${this.defaultSettings.APP_URL}invitation/${this.url_slug}`;

      return link;
    },
    copyInvitationLink() {
      const link = this.getInvitationLink();

      this.copyToClipboard(link);

      Swal.fire({
        title: "Ссылка скопирована!",
        icon: "success",
        confirmButtonColor: "#ef16b0"
      });
    }
  }
};
</script>
