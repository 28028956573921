import ordersService from "../../services/orders.service";

export default {
  state: {
    orders: [],
    subscriptions: null,
    cards: [],
    reasons: null,
    isLoading: {
      subscriptions: false,
      orders: false,
      cards: false
    }
  },
  getters: {
    userOrders: state => state.orders,
    userSubscriptions: state => state.subscriptions,
    userReasons: state => state.reasons,
    userCards: state => state.cards,
    userLoaders: state => state.isLoading
  },
  actions: {
    getUserOrders({ commit }) {
      commit("user_orders_loading_start");

      return new Promise((resolve, reject) => {
        ordersService.getOrders(
          orders => {
            commit("set_user_orders", orders);
            commit("user_orders_loading_finish");
            resolve();
          },
          () => {
            reject();
            commit("user_orders_loading_finish");
          }
        );
      });
    },
    getUserSubscriptions({ commit }) {
      commit("user_subscriptions_loading_start");

      return new Promise((resolve, reject) => {
        ordersService.getSubscriptions(
          subscriptions => {
            commit("set_user_subscriptions", subscriptions);
            commit("user_subscriptions_loading_finish");
            resolve();
          },
          () => {
            reject();
            commit("user_subscriptions_loading_finish");
          }
        );
      });
    },
    getUserCards({ commit }) {
      commit("user_cards_loading_start");

      return new Promise((resolve, reject) => {
        ordersService.getCards(
          cards => {
            commit("set_user_cards", cards);
            commit("user_cards_loading_finish");
            resolve();
          },
          () => {
            reject();
            commit("user_cards_loading_finish");
          }
        );
      });
    },
    removeUserCard({ commit }, card) {
      return new Promise((resolve, reject) => {
        ordersService.removeCard(
          card,
          subscriptions => {
            commit("set_user_subscriptions", subscriptions);
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },
    getReasonUnsubscribeList({ commit }) {
      return new Promise((resolve, reject) => {
        ordersService.getReasonUnsubscribeList(
          reasons => {
            commit("set_reasons", reasons);
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    },
    sendReasonUnsubscribeList(context, payload) {
      return new Promise((resolve, reject) => {
        ordersService.sendReasonUnsubscribeList(
          payload,
          () => {
            resolve();
          },
          () => {
            reject();
          }
        );
      });
    }
  },
  mutations: {
    set_user_subscriptions(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    set_reasons(state, reasons) {
      state.reasons = reasons;
    },
    user_subscriptions_loading_start(state) {
      state.isLoading.subscriptions = true;
    },
    user_subscriptions_loading_finish(state) {
      state.isLoading.subscriptions = false;
    },

    set_user_orders(state, orders) {
      state.orders = orders;
    },
    user_orders_loading_start(state) {
      state.isLoading.orders = true;
    },
    user_orders_loading_finish(state) {
      state.isLoading.orders = false;
    },

    set_user_cards(state, cards) {
      state.cards = cards;
    },
    user_cards_loading_start(state) {
      state.isLoading.cards = true;
    },
    user_cards_loading_finish(state) {
      state.isLoading.cards = false;
    }
  }
};
