var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.counters)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/programs"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:{
        'menu-item-active': isActive && _vm.myProgramsPage === false,
        'menu-item-active': isExactActive && _vm.myProgramsPage === false
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-squares-3 show-on show-on--tablet"}),_c('span',{staticClass:"menu-text"},[_vm._v("Программы ("+_vm._s(_vm.counters.number_of_programs)+")")])])])]}}],null,false,1713187935)}),_c('router-link',{attrs:{"to":"/trainings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-presentation show-on show-on--tablet"}),_c('span',{staticClass:"menu-text"},[_vm._v("Тренировки ("+_vm._s(_vm.counters.number_of_trainings)+")")])])])]}}],null,false,1698925828)}),_c('router-link',{attrs:{"to":"/courses"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:{
        'menu-item-active': isActive && _vm.myCoursesPage === false,
        'menu-item-active': isExactActive && _vm.myCoursesPage === false
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-files-and-folders show-on show-on--tablet"}),_c('span',{staticClass:"menu-text"},[_vm._v("Курсы ("+_vm._s(_vm.counters.number_of_courses)+")")])])])]}}],null,false,288651081)}),_c('router-link',{attrs:{"to":"/recipes"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-black show-on show-on--tablet"}),_c('span',{staticClass:"menu-text"},[_vm._v("Рецепты ("+_vm._s(_vm.counters.number_of_recipes)+")")])])])]}}],null,false,951984529)}),_vm._m(0),_c('router-link',{attrs:{"to":`/fitness-profile/${_vm.userData.url_slug}`},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мой профиль")])])])]}}],null,false,3873685728)}),_c('router-link',{attrs:{"to":"/programs/my"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-menu-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мои программы")])])])]}}],null,false,565586719)}),_c('router-link',{attrs:{"to":"/courses/my"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-folder"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мои курсы")])])])]}}],null,false,4047756479)}),_c('router-link',{attrs:{"to":"/training"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-chronometer"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мои тренировки")])])])]}}],null,false,463893338)}),_c('router-link',{attrs:{"to":"/progress"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-cup"}),_c('span',{staticClass:"menu-text"},[_vm._v("Мой прогресс")])])])]}}],null,false,2225733722)}),_c('router-link',{attrs:{"to":"/favorites"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-star"}),_c('span',{staticClass:"menu-text"},[_vm._v("Избранные тренировки")])])])]}}],null,false,654307146)}),_c('router-link',{attrs:{"to":"/orders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-price-tag"}),_c('span',{staticClass:"menu-text"},[_vm._v("Моя подписка")])])])]}}],null,false,924066740)}),_vm._m(1),_c('li',{staticClass:"menu-item show-on show-on--tablet"},[_c('a',{staticClass:"menu-link",staticStyle:{"align-items":"center"},attrs:{"target":"_blank","href":'https://fitstars.ru/make-a-gift'}},[_c('i',{staticClass:"menu-icon flaticon2-gift"}),_c('span',{staticClass:"menu-text"},[_vm._v("Подарочный сертификат")])])]),_c('router-link',{attrs:{"to":"/invite-friends"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-avatar"}),_c('span',{staticClass:"menu-text"},[_vm._v("Пригласи друга")])])])]}}],null,false,2275820195)}),_vm._m(2),_c('router-link',{attrs:{"to":"/new-profile"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item show-on show-on--tablet",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active'
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-settings"}),_c('span',{staticClass:"menu-text"},[_vm._v("Настройки")])])])]}}],null,false,1146049134)}),_c('li',{staticClass:"menu-item show-on show-on--tablet",on:{"click":_vm.onLogout}},[_vm._m(3)])],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item show-on show-on--tablet",staticStyle:{"height":"18px"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text border-top"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item show-on show-on--tablet",staticStyle:{"height":"18px"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text border-top"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item show-on show-on--tablet",staticStyle:{"height":"18px"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text border-top"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link"},[_c('i',{staticClass:"menu-icon flaticon2-left-arrow-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Выход")])])
}]

export { render, staticRenderFns }