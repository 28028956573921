var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.program && _vm.indexCategory >= 0 && _vm.indexProgram >= 0)?_c('div',{staticClass:"catalog--item__link"},[_c('div',{staticClass:"catalog-item-wrapper"},[(
        (!_vm.userSubscriptions && !_vm.isTrial &&
          (!_vm.program || (_vm.program && _vm.program.tariff == null))) && !_vm.program.is_trial ||
          (_vm.program.hide_for_user && _vm.program.hide_for_user == true)
      )?_c('div',{staticClass:"catalog--item__image catalog--item__image--program no-subscription"},[_c('div',{staticClass:"no-subscription-bg"}),_c('div',{staticClass:"no-subscription-wr"},[_c('img',{staticClass:"no-subscription-img",attrs:{"src":require("@/assets/img/icons-svg/lock-programs.svg"),"alt":""}}),(_vm.program.days_after_create && _vm.program.days_after_create > 0 && _vm.userSubscriptions)?_c('p',{staticClass:"no-subscription-text"},[_vm._v(" Станет доступна "+_vm._s(_vm.programActiveAt(_vm.program))+" ")]):_c('p',{staticClass:"no-subscription-text"},[_vm._v(" Программа недоступна ")])]),_c('img',{staticClass:"img",attrs:{"src":`${_vm.program.pic}`}})]):_c('div',{staticClass:"catalog--item__image catalog--item__image--program"},[_c('img',{staticClass:"img",attrs:{"src":`${_vm.program.pic}`}}),_c('span',{staticClass:"new_flag new_flag--hit",class:`type_${_vm.program.is_hit}`},[_vm._v(" HIT ")]),_c('span',{staticClass:"new_flag",class:`type_${_vm.program.new_flag}`},[_vm._v(" NEW ")])]),_c('div',{staticClass:"catalog--item__description"},[_c('p',{staticClass:"catalog--item__description--name"},[(
            _vm.program.authors && _vm.program.authors[0] && _vm.program.authors[0].name
          )?_c('span',[_vm._v(" "+_vm._s(_vm.program.authors[0].name)+" ")]):_vm._e(),(
            _vm.program.authors &&
              _vm.program.authors[0] &&
              _vm.program.authors[0].surname
          )?_c('span',[_vm._v(" "+_vm._s(_vm.program.authors[0].surname)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex catalog--item__description--data"},[_c('p',{staticClass:"trainings"},[_c('span',{class:`type_${_vm.program.cat_min_level}`,attrs:{"title":"Уровень сложности"}})]),_c('p',{staticClass:"d-flex",staticStyle:{"margin-right":"8px"},attrs:{"title":"Количество тренировок"}},[_c('i',{staticClass:"menu-icon flaticon2-chronometer"}),_vm._v(" "+_vm._s(_vm.program.trainings_count)+" ")]),_c('p',{staticClass:"catalog--item__header--users-couter d-flex",attrs:{"title":"Занимаются по программе"}},[_c('i',{staticClass:"menu-icon flaticon2-user"}),_vm._v(" "+_vm._s(_vm.program.count_done_trainings)+" ")])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }