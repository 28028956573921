// import $ from "jquery";
import recipeService from "../../services/recipe.service";

// action types
export const GET_RECIPE_ITEM = "getRecipeItem";
export const GET_RECIPE_RECIPES = "getRecipeRecipes";
export const GET_RECIPE_COMMENTS = "getRecipeComments";

// mutator types
export const RECIPE_IS_LOADED = "isRecipeLoaded";
export const RECIPE_IS_LOADING = "isRecipeLoading";
export const RECIPE_RECIPES_IS_LOADED = "isRecipeRecipesLoaded";
export const RECIPE_RECIPES_IS_LOADING = "isRecipeRecipesLoading";
export const RECIPE_COMMENTS_IS_LOADED = "isRecipesCommentsLoaded";
export const RECIPE_COMMENTS_IS_LOADING = "isRecipesCommentsLoading";

export default {
  state: {
    recipes: {
      list: [],
      isLoaded: false,
      isLoading: false
    },
    comments: {
      list: [],
      isLoaded: false,
      isLoading: false
    },
    recipe: {
      item: null,
      temp: null,
      isLoaded: false,
      isLoading: false
    }
  },
  getters: {
    recipeItem: state => state.recipe.item,
    recipeRecipesList: state => state.recipes.list,
    recipeCommentsList: state => state.comments.list,
    recipeItemIsLoaded: state => state.recipes.isLoading,
    recipeItemIsLoading: state => state.recipes.isLoaded,
    recipeRecipesListIsLoaded: state => state.recipes.isLoading,
    recipeRecipesListIsLoading: state => state.recipes.isLoaded,
    recipeCommentsListIsLoaded: state => state.recipes.isLoading,
    recipeCommentsListIsLoading: state => state.recipes.isLoaded
  },
  actions: {
    [GET_RECIPE_ITEM]({ commit }, id) {
      commit(RECIPE_IS_LOADING, true);

      return new Promise((resolve, reject) => {
        recipeService.getRecipe(
          id,
          response => {
            commit(GET_RECIPE_ITEM, response);

            commit(RECIPE_IS_LOADED, true);
            commit(RECIPE_IS_LOADING, false);

            resolve(response);
          },
          error => {
            commit(RECIPE_IS_LOADING, false);
            commit(RECIPE_IS_LOADED, false);

            reject(error);
          }
        );
      });
    }
    // [GET_RECIPE_COMMENTS]({ commit }, comments) {
    //   commit(RECIPE_RECIPES_IS_LOADING, true);

    //   return new Promise((resolve, reject) => {
    //     recipeService.getComments(
    //       comments.page,
    //       comments.trainingId,
    //       response => {
    //         commit(GET_RECIPE_COMMENTS, response);

    //         commit(RECIPE_COMMENTS_IS_LOADED, true);
    //         commit(RECIPE_COMMENTS_IS_LOADING, false);

    //         resolve(response);
    //       },
    //       error => {
    //         commit("training_comments_loading_finish");

    //         reject(error);
    //       }
    //     );
    //   });
    // }
    // [GET_RECIPE_RECIPES]({ commit }, recipeType) {
    //   commit(RECIPE_RECIPES_IS_LOADING, true);

    //   return new Promise((resolve, reject) => {
    //     recipeService.getRecipe(
    //       recipeType,
    //       response => {
    //         commit(GET_RECIPE_RECIPES, response);

    //         commit(RECIPE_RECIPES_IS_LOADED, true);
    //         commit(RECIPE_RECIPES_IS_LOADING, false);

    //         resolve(response);
    //       },
    //       error => {
    //         commit(RECIPE_RECIPES_IS_LOADING, false);
    //         commit(RECIPE_RECIPES_IS_LOADED, false);

    //         reject(error);
    //       }
    //     );
    //   });
    // }
  },
  mutations: {
    [GET_RECIPE_ITEM](state, recipe) {
      state.recipe.item = recipe;
      state.recipe.temp = recipe;
    },
    // [GET_RECIPE_COMMENTS](state, comments) {
    //   // console.log(state, comments);
    // },
    // [GET_RECIPE_RECIPES](state, recipes) {
    //   console.log(state, recipes);
    // },
    [RECIPE_IS_LOADED](state, isLoaded) {
      state.recipes.isLoaded = isLoaded;
    },
    [RECIPE_IS_LOADING](state, isLoading) {
      state.recipes.isLoading = isLoading;
    },
    [RECIPE_RECIPES_IS_LOADED](state, isLoaded) {
      state.recipes.isLoaded = isLoaded;
    },
    [RECIPE_RECIPES_IS_LOADING](state, isLoading) {
      state.recipes.isLoading = isLoading;
    },
    [RECIPE_COMMENTS_IS_LOADED](state, isLoaded) {
      state.comments.isLoaded = isLoaded;
    },
    [RECIPE_COMMENTS_IS_LOADING](state, isLoading) {
      state.comments.isLoading = isLoading;
    }
    // set_user_data(state, userData) {
    //   state.userData = userData;
    // },
    // set_BMR(state, BMR) {
    //   state.BMR = BMR;
    // },
    // set_BJU(state, BJU) {
    //   state.BJU = BJU;
    // },
    // set_recipes(state, recipes) {
    //   state.recipes = recipes;
    //   state.currentRecipes = [];

    //   for (const eating in state.recipes) {
    //     for (const recipe of state.recipes[eating]) {
    //       state.currentRecipes.push(parseInt(recipe.id, 10));
    //     }
    //   }

    //   state.currentRecipes = state.currentRecipes.slice(0, 11);
    // },
    // clear_recipe(state) {
    //   state.recipe = {};
    // },
    // set_recipe(state, recipe) {
    //   state.recipe = recipe;
    // },
    // update_current_recipes(state, params) {
    //   const { eating } = params.params;
    //   const { dish } = params.params;
    //   const { id } = params.params || null;
    //   const editedRecipeIndex = recipesMap.indexOf(`${eating}.${dish}`);

    //   switch (params.action) {
    //     case "delete": {
    //       state.currentRecipes[editedRecipeIndex] = 0;
    //       break;
    //     }
    //     case "update": {
    //       state.currentRecipes[editedRecipeIndex] = parseInt(id, 10);
    //       break;
    //     }
    //     default: {
    //       break;
    //     }
    //   }
    // },
    // set_available_recipes(state, recipes) {
    //   state.availableRecipes = recipes;
    // },
    // set_food_access(state, access) {
    //   state.isHaveDataForCalculate = access;
    // },
    // user_data_loading_start(state) {
    //   state.isLoading.userData = true;
    // },
    // user_data_loading_finish(state) {
    //   state.isLoading.userData = false;
    // },
    // recipes_loading_start(state) {
    //   state.isLoading.recipes = true;
    // },
    // recipes_loading_finish(state) {
    //   state.isLoading.recipes = false;
    // },
    // recipe_loading_start(state) {
    //   state.isLoading.recipe = true;
    // },
    // recipe_loading_finish(state) {
    //   state.isLoading.recipe = false;
    // },
    // available_recipes_loading_start(state) {
    //   state.isLoading.availableRecipes = true;
    // },
    // available_recipes_loading_finish(state) {
    //   state.isLoading.availableRecipes = false;
    // }
  }
};
