<template>
  <div
    v-if="program && trainer"
    :class="{
      'no-subscription': !userSubscriptions && program.tariff == null
    }"
    class="fs-ptile fs-ptile--wrap"
  >
    <div class="training-header">
      <div class="training-header__img-wr">
        <img :src="`${program.pic}`" alt="program image" />
      </div>
      <div class="training-header__info">
        <div class="training-name-block">
          <div class="training-name-block__wr">
            <div class="d-flex" style="flex-wrap: wrap">
              <h1 class="training-title">{{ program.name }}</h1>
              <div class="d-flex">
                <span class="training-raiting">
                  {{ program.rating.toFixed(1) }}
                </span>
                <div class="card-toolbar mb-auto show-on show-on--large">
                  <div
                    class="dropdown dropdown-inline"
                    data-toggle="tooltip"
                    title=""
                    data-placement="left"
                    data-original-title="Quick actions"
                  >
                    <a
                      href="#"
                      class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="ki ki-bold-more-hor"></i>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-sm dropdown-menu-right"
                    >
                      <!--begin::Navigation-->
                      <ul class="navi navi-hover">
                        <li
                          class="navi-item cursor-pointer"
                          @click="switchProgram"
                        >
                          <p
                            v-if="program.is_selected === true"
                            class="navi-link"
                          >
                            <span class="navi-text">Завершить</span>
                          </p>
                          <p
                            v-if="program.is_selected === false"
                            class="navi-link"
                          >
                            <span class="navi-text">Начать</span>
                          </p>
                        </li>
                      </ul>
                      <!--end::Navigation-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--begin::Toolbar-->
            <div class="card-toolbar mb-auto hide-on hide-on--large">
              <div
                class="dropdown dropdown-inline"
                data-toggle="tooltip"
                title=""
                data-placement="left"
                data-original-title="Quick actions"
              >
                <a
                  href="#"
                  class="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="ki ki-bold-more-hor"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <!--begin::Navigation-->
                  <ul class="navi navi-hover">
                    <li class="navi-item cursor-pointer" @click="switchProgram">
                      <p v-if="program.is_selected === true" class="navi-link">
                        <span class="navi-text">Завершить</span>
                      </p>
                      <p v-if="program.is_selected === false" class="navi-link">
                        <span class="navi-text">Начать</span>
                      </p>
                    </li>
                  </ul>
                  <!--end::Navigation-->
                </div>
              </div>
            </div>
            <!--end::Toolbar-->
          </div>
        </div>
        <div>
          <p class="training-trainer">{{ trainer.full_name }}</p>
        </div>

        <div
          class="training-statistic flex flex--end flex--space-between hide-on--large"
        >
          <div class="d-flex flex-wrap" style="width: 100%">
            <div class="d-flex flex-wrap program-my--info-wr">
              <div class="d-flex flex-wrap program-info">
                <span
                  class="d-block font-weight-boldest mb-4 program-info--title"
                  style="width: 100%"
                >
                  Релиз
                </span>
                <span
                  class="btn-light-pink font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
                >
                  {{ getFormattedDate(program.date_create, "DD.MM.YYYY") }}
                </span>
              </div>
              <div class="col-divider"></div>
              <div class="d-flex flex-wrap program-info">
                <span
                  class="d-block font-weight-boldest mb-4 program-info--title"
                  style="width: 100%"
                >
                  Проходят
                </span>
                <span
                  class="btn-light-green font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
                >
                  {{ `${program.count_last_30_days_done_trainings} чел.` }}
                </span>
              </div>
              <div class="col-divider"></div>
              <div class="d-flex flex-wrap program-info">
                <span
                  class="d-block font-weight-boldest mb-4 program-info--title"
                  style="width: 100%"
                >
                  Пол (ж/м)
                </span>
                <span
                  class="btn-light-orange font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
                >
                  {{ program.gender_relation }}
                </span>
              </div>
            </div>
            <div class="flex-row-fluid program-my--progres">
              <span class="d-block font-weight-boldest mb-4">
                Твой прогресс
              </span>
              <div class="d-flex align-items-center pt-2">
                <div class="progress progress-xs mt-2 mb-2 w-100">
                  <div
                    class="progress-bar"
                    :style="`width: ${getProgress(program.progress)};`"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    role="progressbar"
                  ></div>
                </div>
                <span class="ml-3 font-weight-bolder">
                  {{ `${getProgress(program.progress)}` }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-divider"></div>
          <div class="d-flex">
            <button
              class="btn"
              @click="switchProgram"
              :class="{
                'btn--succses__selected': program.is_selected === true,
                'btn--succses': program.is_selected === false
              }"
            >
              {{
                `${
                  program.is_selected === true ? "Завершить" : "Начать"
                } программу`
              }}
            </button>
            <div class="col-divider"></div>
            <button class="btn btn--to-active" @click="showDis()">
              Посмотреть описание
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex flex--end flex--space-between show-on show-on--large mt-5 training-header-mobile"
    >
      <div class="d-flex flex-wrap">
        <div class="d-flex flex-wrap program-my--info-wr">
          <div class="d-flex flex-wrap program-info">
            <span
              class="d-block font-weight-boldest mb-5 program-info--title"
              style="width: 100%"
            >
              Релиз
            </span>
            <span
              class="btn-light-pink font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
            >
              {{ getFormattedDate(program.date_create, "DD.MM.YYYY") }}
            </span>
          </div>
          <div class="col-divider"></div>
          <div class="d-flex flex-wrap program-info">
            <span
              class="d-block font-weight-boldest mb-5 program-info--title"
              style="width: 100%"
            >
              Проходят
            </span>
            <span
              class="btn-light-green font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
            >
              {{ `${program.count_last_30_days_done_trainings} чел.` }}
            </span>
          </div>
          <div class="col-divider"></div>
          <div class="d-flex flex-wrap program-info">
            <span
              class="d-block font-weight-boldest mb-5 program-info--title"
              style="width: 100%"
            >
              Пол (ж/м)
            </span>
            <span
              class="btn-light-orange font-weight-boldest btn-upper btn-text cursor-auto program-info--btn"
            >
              {{ program.gender_relation }}
            </span>
          </div>
        </div>
        <div class="flex-row-fluid program-my--progres">
          <span class="d-block font-weight-boldest mb-4">Твой прогресс</span>
          <div class="d-flex align-items-center pt-2">
            <div class="progress progress-xs w-100">
              <div
                class="progress-bar"
                :style="`width: ${getProgress(program.progress)};`"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                role="progressbar"
              ></div>
            </div>
            <span class="ml-3 font-weight-bolder">
              {{ `${getProgress(program.progress)}` }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex mt-5">
        <button
          class="btn"
          :class="{
            'btn--succses__selected': program.is_selected === true,
            'btn--succses': program.is_selected === false
          }"
          @click="switchProgram"
        >
          {{ `${program.is_selected === true ? "Завершить" : "Начать"}` }}
        </button>
        <div class="col-divider"></div>
        <button class="btn btn--to-active" @click="showDis()">Описание</button>
      </div>
    </div>
    <div v-if="textDescriptionOpen" class="training-modal" :class="{ open: textDescriptionOpen }">
      <div class="training-modal__bg" @click="showDis()"></div>
      <div class="d-flex flex--wrap training-modal__conteiner">
        <div style="position: relative; width: 100%">
          <div class="training-modal__title">Описание программы</div>
          <span class="training-modal__close" @click="showDis()"></span>
        </div>
        <div class="training-modal__ifame">
          <iframe
            id="program-video"
            allowfullscreen="1"
            frameborder="0"
            class="yf-border-radius"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title="YouTube video player"
            width="100%"
            height="100%"
            :src="
              `https://www.youtube.com/embed/${getYoutubeId(
                program.description_link
              )}?rel=0&amp;showinfo=0&amp;enablejsapi=1&amp;origin=${origin}&amp;widgetid=2`
            "
            data-gtm-yt-inspected-9129619_225="true"
            data-gtm-yt-inspected-9129619_67="true"
            data-gtm-yt-inspected-9129619_79="true"
            data-gtm-yt-inspected-9129619_97="true"
            data-gtm-yt-inspected-9129619_111="true"
            data-gtm-yt-inspected-1_25="true"
          ></iframe>
        </div>

        <div class="training-modal__info" style="width: 100%">
          <div class="training-modal__info--level">
            <span>Сложность:</span>
            <div
              :class="
                `complexity-level complexity-level--${program.cat_min_level}`
              "
            ></div>
          </div>
          <div class="training-modal__info--goal">
            <span>Направление:</span> {{ program.goal }}
          </div>
          <div
            class="training-modal__info--text"
            v-if="program.description"
          >
            <span style="float: left; padding-right: 5px;">
              Описание:
            </span>
            <div v-html="program.description"></div>
          </div>
        </div>
        <div class="flex flex-center mt-9">
          <button
            class="btn"
            :class="{
              'btn--succses__selected': program.is_selected === true,
              'btn--succses': program.is_selected === false
            }"
            @click="switchProgram"
          >
            {{
              `${
                program.is_selected === true ? "Завершить" : "Начать"
              } программу`
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getYoutubeId, getFormattedDate } from "@/assets/js/helpers";

export default {
  name: "FSTrainingProgram",
  props: {
    program: Object,
    trainer: Object
  },
  data() {
    return {
      origin: null,
      textDescriptionOpen: false
    };
  },
  computed: {
    ...mapGetters(["userSubscriptions"]),
    userSubscriptions() {
      return this.$store.getters.userSubscriptions;
    }
  },
  beforeDestroy() {
    this.$root.$off("page_training__switch_program");
  },
  mounted() {
    let __self__ = this;

    this.origin = document.location.origin;

    this.$root.$on("page_training__switch_program", function() {
      __self__.switchProgram();
    });
  },
  methods: {
    getYoutubeId,
    getFormattedDate,
    getProgress(progress) {
      let result = "0%";

      if (progress && progress != 0) {
        result = progress.split("/");
        result = `${parseInt((result[0] / result[1]) * 100)}%`;
      }

      return result;
    },
    switchProgram() {
      if (this.program.is_selected == true) {
        window.carrotquest.track(`Завершил программу "${this.program.name}"`);
      } else {
        window.carrotquest.track(`Начал программу "${this.program.name}"`);
      }

      this.$store
        .dispatch("switchProgramMyProgram", {
          pack_id: this.program.id
        })
        .then(() => {
          if (
            this.program.is_selected == true &&
            this.$route.name !== "training-pack-pack"
          ) {
            this.$router.push({
              name: "training-pack-pack",
              params: {
                pack: this.program.id
              }
            });

            this.$root.$emit("page_training__start_program");
          }

          this.$notify({
            type: "success",
            title:
              this.program.is_selected == true
                ? `Программа добавлена в "Мои программы"`
                : `Программа удалена из "Мои программы"`
          });
        });
    },
    showDis() {
      this.textDescriptionOpen = !this.textDescriptionOpen;
    }
  }
};
</script>
