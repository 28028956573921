var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trainings && _vm.program)?_c('div',{staticClass:"training-sidebar",class:{
    'no-subscription': !_vm.userSubscriptions && _vm.program.tariff == null
  }},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{ref:"training-block-tarinings--header",staticClass:"training-sidebar__title flex--center"},[_vm._v(" План тренировок "),_c('div',{staticClass:"card-toolbar mb-auto"},[_c('div',{staticClass:"dropdown dropdown-inline",attrs:{"data-toggle":"tooltip","title":"","data-placement":"left","data-original-title":"Quick actions"}},[_vm._m(0),_c('div',{staticClass:"dropdown-menu dropdown-menu-sm dropdown-menu-right"},[_c('ul',{staticClass:"navi navi-hover"},[_c('li',{staticClass:"navi-item cursor-pointer",on:{"click":_vm.switchProgram}},[(_vm.program.is_selected === true)?_c('p',{staticClass:"navi-link"},[_c('span',{staticClass:"navi-text",staticStyle:{"font-weight":"400"}},[_vm._v(" Завершить ")])]):_vm._e(),(_vm.program.is_selected === false)?_c('p',{staticClass:"navi-link"},[_c('span',{staticClass:"navi-text",staticStyle:{"font-weight":"400"}},[_vm._v(" Начать ")])]):_vm._e()])])])])])])]),_c('div',{staticClass:"training-list",style:({
      height: `${_vm.height ? _vm.height : 'auto'}`
    })},[_vm._m(1),_c('div',{staticClass:"frame",attrs:{"id":"frame1"}},[_c('div',{staticClass:"slidee"},_vm._l((_vm.trainings),function(trainingItem,indexTraining){return _c('div',{key:`training-${indexTraining}-${trainingItem.id}`,staticClass:"training-list__item",class:{
            'training-list__item-done': trainingItem.date_last_done,
            'training-list__item-next': trainingItem.id === _vm.training.id
          }},[(!trainingItem.isRestDay)?_c('div',{staticClass:"cursor-pointer training-list__item--container",on:{"click":function($event){return _vm.switchTraining(trainingItem, indexTraining)}}},[_c('div',{staticClass:"training-list__item--training-number"},[_vm._v(" "+_vm._s(indexTraining + 1)+" ")]),_c('div',{staticClass:"training-list__item--img"},[(trainingItem.poster_thumb)?_c('img',{attrs:{"src":trainingItem.poster_thumb}}):(trainingItem.poster)?_c('img',{attrs:{"src":trainingItem.poster}}):_c('img',{attrs:{"src":`${_vm.$APP_URL}${trainingItem.video_local_url}`}}),_c('div',{staticClass:"training-list__item--duration"},[_vm._v(" "+_vm._s(`${parseInt(trainingItem.duration / 60)}:${ trainingItem.duration % 60 >= 10 ? trainingItem.duration % 60 : trainingItem.duration % 60 > 0 ? `0${trainingItem.duration % 60}` : "00" }`)+" ")])]),_c('div',{staticClass:"training-list__item--info-wr"},[_c('div',{staticClass:"training-list__item--title-wr"},[_c('div',{staticClass:"training-list__item--title"},[_c('p',[_c('span',{staticStyle:{"padding-right":"8px"}},[_vm._v(" "+_vm._s(trainingItem.training_name)+" ")]),_c('span',{staticStyle:{"display":"block"}},[_c('span',{class:`complexity-level complexity-level--${Math.ceil(
                            trainingItem.training_level / 3
                          )}`,attrs:{"id":`training-list__item--title-${indexTraining}`}}),_c('b-tooltip',{attrs:{"target":`training-list__item--title-${indexTraining}`,"variant":"light","placement":"bottom"}},[_vm._v(" "+_vm._s(`Сложность: ${ Math.ceil(trainingItem.training_level / 3) === 1 ? "легкая" : Math.ceil(trainingItem.training_level / 3) === 2 ? "средняя" : "высокая" }`)+" ")]),_c('span',{staticClass:"calories-done"},[_vm._v(" "+_vm._s(`${parseInt( (trainingItem.duration / 60) * trainingItem.calories_coefficient )} Ккал`)+" ")])],1)])])]),_c('div',{staticClass:"training-list__item--tags-wr"},[(
                    trainingItem.training_type &&
                      trainingItem.training_type.name
                  )?_c('span',{staticClass:"training-list__item--tag first"},[_vm._v(" "+_vm._s(trainingItem.training_type.name)+" ")]):_vm._e(),_vm._v(" : "),_c('span',{staticClass:"training-list__item--tag"},_vm._l((trainingItem.parts),function(part,indexPart){return _c('span',{key:`part-${trainingItem.id}-${trainingItem.program_id}-${part.id}`},[_vm._v(" "+_vm._s(part.name + (indexPart + 1 === trainingItem.parts.length ? "" : ", "))+" ")])}),0)]),_c('div',{staticClass:"training-list__item-data",class:{
                  'training-list__item-data--done':
                    trainingItem.date_last_done,
                  'training-list__item-data--next-day':
                    trainingItem.is_next_planed == true
                }},[(trainingItem.date_last_done)?_c('span',[_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"src":require("@/assets/img/training/check.svg")}}),_vm._v(" "+_vm._s(`Выполнена: ${_vm.getFormattedDate( trainingItem.date_last_done, "DD.MM" )}`)+" ")]):(
                    trainingItem.is_next_planed &&
                      trainingItem.is_next_planed == true &&
                      trainingItem.date_planned === _vm.tomorrow()
                  )?_c('span',[_vm._v(" Завтра ")]):(
                    trainingItem.is_next_planed &&
                      trainingItem.is_next_planed == true &&
                      trainingItem.date_planned === _vm.today()
                  )?_c('span',[_vm._v(" Сегодня ")]):(
                    trainingItem.date_planned &&
                      trainingItem.date_planned !== false
                  )?_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(`${_vm.getFormattedDate( trainingItem.date_planned, "ddd., DD.MM" )}`)+" ")]):_vm._e()])])]):_c('div',{staticClass:"training-list__item training-list__item-rest"},[_vm._m(2,true),_c('div',{staticClass:"training-list__item--info-wr"},[_vm._m(3,true),_vm._m(4,true),_c('div',{staticClass:"training-list__item-data",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.getTrainingRelaxDate(trainingItem))+" ")])])])])}),0)])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"btn btn-clean btn-hover-light-primary btn-sm btn-icon",attrs:{"href":"#","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"ki ki-bold-more-hor"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollbar",attrs:{"id":"jsid-scrollbar1"}},[_c('div',{staticClass:"handle"},[_c('div',{staticClass:"mousearea"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"training-list__item--img"},[_c('img',{attrs:{"src":require("@/assets/img/training/relax.jpeg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"training-list__item--title-wr"},[_c('div',{staticClass:"training-list__item--title"},[_vm._v("День отдыха!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"training-list__item--tags-wr"},[_c('span',{staticClass:"training-list__item--tag first"},[_vm._v(" Расслабоение ")]),_vm._v(" : "),_c('span',{staticClass:"training-list__item--tag"},[_vm._v("на все тело 😉")])])
}]

export { render, staticRenderFns }